import React from "react";
import { useParams } from "react-router-dom";
import { Grid, List, ListItem } from '@mui/material';
import { ThemeProvider, styled } from "@mui/material/styles"

// local module
import { useAuth } from "../context/GlobalContext";
import { useWindowSize } from "../context/WindowSizeContext";
import { Ibreadcrumb, IcontentMenuPrimary, initContentMenuPrimary } from  "../type/ContentType";
import { globalTheme } from "../layout/GlobalStyle";
import { configPrimary } from "../config/primary";
import MainContent from "../layout/MainContent";
import MainContentSubSmall from "../layout/MainContentSubSmall";

// image
import HeaderTextImageEarlyEn from '../image/header_text_early_en.png';
import HeaderTextImageEarlyJa from '../image/header_text_early_ja.png';
import HeaderTextImagePrimaryEn from '../image/header_text_primary_en.png';
import HeaderTextImagePrimaryJa from '../image/header_text_primary_ja.png';
import HeaderTextImageDigitalEn from '../image/header_text_digital_en.png';
import HeaderTextImageDigitalJa from '../image/header_text_digital_ja.png';

import HeaderImageEarly from '../image/char_early_02.png';
import HeaderImagePrimary from '../image/char_primary_02.png';
import HeaderImageDigital from '../image/char_digital_02.png';

//---------------------------------------------------------------------
// const
//
const defaultWidth = "100%";
const defaultHeight = "128px";

//-----------------------------------------------------------------------
// styled component (header)
//
const StyledHeaderContent = styled("div")(({ theme }) => ({
  // color
  backgroundImage: "radial-gradient(#f74545 0.5px, #e3b3ce 0.5px)",
  backgroundcolor: "#e3b3ce",
  backgroundSize: "10px 10px",
  // size
  width: defaultWidth,
  height: defaultHeight,
  borderBottomLeftRadius: "8px",
  borderBottomRightRadius: "8px",
  // font / text
  display: "flex",
  alignItems: "center",
}));

const StyledHeaderSpan = styled("span")(({ theme }) => ({
  // color
  color: theme.palette.primary.contrastText,
  // font / text
  fontSize: "small",
  padding: "1em",
}));

const StyledHeaderTextImage = styled("img")(({ theme }) => ({
  // size
  maxHeight: "128px",
  maxWidth: "600px",
}));

const StyledHeaderTextSmallImage = styled("img")(({ theme }) => ({
  // size
  maxWidth: "192px",
}));

const StyledHeaderImage = styled("img")(({ theme }) => ({
  // size
  height: "128px",
  width: "128px",
}));

//-----------------------------------------------------------------------
// styled component (contentmenu content)
//
const StyledContentList = styled(List)(({ theme }) => ({
  // font / text
  paddingTop: ".5em",
  paddingBottom: ".4em",
}));

const StyledContentChildDiv = styled("div")(({ theme }) => ({
}));

const StyledContentListItem = styled(ListItem)(({ theme }) => ({
  // backgroung
  backgroundColor: theme.palette.secondary.light,
  // size
  height: "50px",
  borderTopLeftRadius: "8px",
  borderTopRightRadius: "8px",
  // content
  position: "relative",
  fontSize: "small",
}));

const StyledContentChildListItem = styled(ListItem)(({ theme }) => ({
  // backgroung
  backgroundColor: theme.palette.info.main,
  // size
  height: "50px",
  // content
  position: "relative",
  fontSize: "small",
}));

const StyledContentUnitListItem = styled(ListItem)(({ theme }) => ({
  // backgroung
  backgroundColor: theme.palette.info.light,
  // size
  height: "50px",
  // content
  position: "relative",
  fontSize: "small",
}));

const StyledContentListItemLink = styled("a")(({ theme }) => ({
  // position
  display: "block",
  position: "absolute",
  top: "0",
  left: "0",
  width: "100%",
  height: "100%",
}));

const StyledContentListItemSpan = styled("span")(({ theme }) => ({
  // position
  display: "flex",
  alignItems: "center",
  height: "100%",
  // color
  color: theme.palette.primary.contrastText,
  // font / text
  fontSize: "small",
  paddingLeft: ".4em",
}));

const StyledContentListItemSpanSmall = styled("span")(({ theme }) => ({
  // position
  display: "flex",
  alignItems: "center",
  height: "100%",
  // color
  color: theme.palette.primary.contrastText,
  // font / text
  fontSize: "xx-small",
  paddingLeft: ".4em",
}));

const StyledContentUnitListItemP = styled("p")(({ theme }) => ({
  // color
  color: theme.palette.primary.contrastText,
  // font / text
  fontSize: "small",
  padding: ".4em",
}));

//-----------------------------------------------------------------------
// styled component (content)
//
const StyledP = styled("p")(({ theme }) => ({
  // color
  color: theme.palette.primary.contrastText,
  // font / text
  fontSize: "small",
  padding: ".4em",
}));

const StyledSpacer = styled("div")(({ theme }) => ({
  // backgroung
  // backgroundColor: "#FFFFFF",
  width: '3px'
}));

const StyledSpacer2 = styled("div")(({ theme }) => ({
  // backgroung
  // backgroundColor: "#FFFFFF",
  width: '10px'
}));

const StyledSpacer3 = styled("div")(({ theme }) => ({
  // backgroung
  // backgroundColor: "#FFFFFF",
  width: '15px'
}));

const StyledSpacer4 = styled("div")(({ theme }) => ({
  // backgroung
  // backgroundColor: "#FFFFFF",
  width: '37px'
}));

//-----------------------------------------------------------------------
// function component
//
const Index: React.FC = () => {

  //---------------------------------------------------------------------
  // context
  //
  const params = useParams();
  const {isEnglish} = useAuth();
  const [width, height] = useWindowSize();

  //---------------------------------------------------------------------
  // const
  //
  const pageKey    = "/" + params.primary

  const getContentMenuPrimary = (tempArray:IcontentMenuPrimary[]): IcontentMenuPrimary => {
    let pageKeyItems: string[] = pageKey.split('/');
    if(pageKeyItems.length > 1 && pageKeyItems[0] === ""){
      let tempContentMenuPrimaryArrayFiltered = (tempArray.length > 0) ? 
      tempArray.filter(item => item.content_menu_primary_key === pageKeyItems[1]) : [];
      return((tempContentMenuPrimaryArrayFiltered.length > 0) ? tempContentMenuPrimaryArrayFiltered[0] : initContentMenuPrimary);
    }else{
      return(initContentMenuPrimary);
    }
  }
  const contentMenuPrimaryArray: IcontentMenuPrimary[] = configPrimary;
  const contentMenuPrimary: IcontentMenuPrimary = getContentMenuPrimary(contentMenuPrimaryArray);

  const localBreadcrumbs: Ibreadcrumb[] = [
    {
      key: "home",
      path: "/",
      text: isEnglish ? "HOME" : "ホーム",
    },
  ]

  const localBreadcrumbLastItem: Ibreadcrumb = {
    key: contentMenuPrimary.content_menu_primary_key,
    path: contentMenuPrimary.content_menu_primary_path,
    text: isEnglish ? contentMenuPrimary.content_menu_primary_text_en : contentMenuPrimary.content_menu_primary_text_ja,
  }

  React.useEffect(() => {
    if (window.location.hash) {
      const hashSave = window.location.hash
      window.location.hash = ""
      window.location.hash = hashSave
    }
  },[])

  //-----------------------------------------------------------------------
  // context
  //
  if(width > 1080){
    return (
      <MainContent
        mainContentBreadcrumbs={localBreadcrumbs}
        mainContentBreadcrumbLastItem={localBreadcrumbLastItem}
        contentMenuPrimaryArray={contentMenuPrimaryArray}
      >
        <ThemeProvider theme={globalTheme}>
          <Grid container>
            <Grid item xs={12}>
              <StyledHeaderContent>
                {(isEnglish && contentMenuPrimary.content_menu_primary_key === "early"   ) && <StyledHeaderTextImage alt={"header_text_img"} src={HeaderTextImageEarlyEn}/>}
                {(isEnglish && contentMenuPrimary.content_menu_primary_key === "primary" ) && <StyledHeaderTextImage alt={"header_text_img"} src={HeaderTextImagePrimaryEn}/>}
                {(isEnglish && contentMenuPrimary.content_menu_primary_key === "digital" ) && <StyledHeaderTextImage alt={"header_text_img"} src={HeaderTextImageDigitalEn}/>}
                {(!isEnglish && contentMenuPrimary.content_menu_primary_key === "early"   ) && <StyledHeaderTextImage alt={"header_text_img"} src={HeaderTextImageEarlyJa}/>}
                {(!isEnglish && contentMenuPrimary.content_menu_primary_key === "primary" ) && <StyledHeaderTextImage alt={"header_text_img"} src={HeaderTextImagePrimaryJa}/>}
                {(!isEnglish && contentMenuPrimary.content_menu_primary_key === "digital" ) && <StyledHeaderTextImage alt={"header_text_img"} src={HeaderTextImageDigitalJa}/>}
                {contentMenuPrimary.content_menu_primary_key === "early"   && <StyledHeaderImage alt={"header_img"} src={HeaderImageEarly}/>}
                {contentMenuPrimary.content_menu_primary_key === "primary" && <StyledHeaderImage alt={"header_img"} src={HeaderImagePrimary}/>}
                {contentMenuPrimary.content_menu_primary_key === "digital" && <StyledHeaderImage alt={"header_img"} src={HeaderImageDigital}/>}
              </StyledHeaderContent>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              {contentMenuPrimary.content_menu_secondary_array.map((contentMenuItem) => {
                return(
                  <StyledContentList>
                    <StyledContentListItem>
                      <StyledContentListItemSpan id={contentMenuItem.content_menu_secondary_key}>
                        <StyledSpacer/>{" ▶ "}{isEnglish ? contentMenuItem.content_menu_secondary_text_en : contentMenuItem.content_menu_secondary_text_ja}
                      </StyledContentListItemSpan>
                    </StyledContentListItem>
                    {contentMenuItem.content_menu_tertiary_array.map((contentMenuChild) => {
                      return(
                        <StyledContentChildDiv>
                          <StyledContentChildListItem>
                            <StyledContentListItemSpan id={contentMenuChild.content_menu_tertiary_key}>
                              <StyledSpacer2/>{" ◆ "}{isEnglish ? contentMenuChild.content_menu_tertiary_text_en : contentMenuChild.content_menu_tertiary_text_ja}
                            </StyledContentListItemSpan>
                          </StyledContentChildListItem>
                          {contentMenuChild.content_menu_unit_array.map((contentMenuChiledUnit) => {
                            return(
                              <StyledContentUnitListItem>
                                <StyledContentUnitListItemP>
                                  <StyledContentListItemSpan id={contentMenuChiledUnit.content_menu_unit_key}>
                                    <StyledSpacer3/>{" >> "}{isEnglish ? contentMenuChiledUnit.content_menu_unit_text_en : contentMenuChiledUnit.content_menu_unit_text_ja}
                                  </StyledContentListItemSpan>
                                  <StyledContentListItemLink href={contentMenuChiledUnit.content_menu_unit_path}/>
                                </StyledContentUnitListItemP>
                              </StyledContentUnitListItem>
                            );
                          })}
                        </StyledContentChildDiv>
                      );
                    })}
                  </StyledContentList>
                );
              })}
            </Grid>
          </Grid>
        </ThemeProvider>
      </MainContent>
    );
  }else{
    {
      return (
        <MainContentSubSmall
          mainContentBreadcrumbs={localBreadcrumbs}
          mainContentBreadcrumbLastItem={localBreadcrumbLastItem}
          contentMenuPrimaryArray={contentMenuPrimaryArray}
        >
          <ThemeProvider theme={globalTheme}>
            <Grid container>
              <Grid item xs={12}>
                <StyledHeaderContent>
                  {(isEnglish && contentMenuPrimary.content_menu_primary_key === "early"   ) && <StyledHeaderTextSmallImage alt={"header_text_img"} src={HeaderTextImageEarlyEn}/>}
                  {(isEnglish && contentMenuPrimary.content_menu_primary_key === "primary" ) && <StyledHeaderTextSmallImage alt={"header_text_img"} src={HeaderTextImagePrimaryEn}/>}
                  {(isEnglish && contentMenuPrimary.content_menu_primary_key === "digital" ) && <StyledHeaderTextSmallImage alt={"header_text_img"} src={HeaderTextImageDigitalEn}/>}
                  {(!isEnglish && contentMenuPrimary.content_menu_primary_key === "early"   ) && <StyledHeaderTextSmallImage alt={"header_text_img"} src={HeaderTextImageEarlyJa}/>}
                  {(!isEnglish && contentMenuPrimary.content_menu_primary_key === "primary" ) && <StyledHeaderTextSmallImage alt={"header_text_img"} src={HeaderTextImagePrimaryJa}/>}
                  {(!isEnglish && contentMenuPrimary.content_menu_primary_key === "digital" ) && <StyledHeaderTextSmallImage alt={"header_text_img"} src={HeaderTextImageDigitalJa}/>}
                  {contentMenuPrimary.content_menu_primary_key === "early"   && <StyledHeaderImage alt={"header_img"} src={HeaderImageEarly}/>}
                  {contentMenuPrimary.content_menu_primary_key === "primary" && <StyledHeaderImage alt={"header_img"} src={HeaderImagePrimary}/>}
                  {contentMenuPrimary.content_menu_primary_key === "digital" && <StyledHeaderImage alt={"header_img"} src={HeaderImageDigital}/>}
                </StyledHeaderContent>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                {contentMenuPrimary.content_menu_secondary_array.map((contentMenuItem) => {
                  return(
                    <StyledContentList>
                      <StyledContentListItem>
                        <StyledContentListItemSpan id={contentMenuItem.content_menu_secondary_key}>
                          <StyledSpacer/>{" ▶ "}{isEnglish ? contentMenuItem.content_menu_secondary_text_en : contentMenuItem.content_menu_secondary_text_ja}
                        </StyledContentListItemSpan>
                      </StyledContentListItem>
                      {contentMenuItem.content_menu_tertiary_array.map((contentMenuChild) => {
                        return(
                          <StyledContentChildDiv>
                            <StyledContentChildListItem>
                              <StyledContentListItemSpan id={contentMenuChild.content_menu_tertiary_key}>
                                <StyledSpacer2/>{" ◆ "}{isEnglish ? contentMenuChild.content_menu_tertiary_text_en : contentMenuChild.content_menu_tertiary_text_ja}
                              </StyledContentListItemSpan>
                            </StyledContentChildListItem>
                            {contentMenuChild.content_menu_unit_array.map((contentMenuChiledUnit) => {
                              return(
                                <StyledContentUnitListItem>
                                <StyledContentUnitListItemP>
                                  <StyledContentListItemSpan id={contentMenuChiledUnit.content_menu_unit_key}>
                                    <StyledSpacer3/>{" >> "}{isEnglish ? contentMenuChiledUnit.content_menu_unit_text_en : contentMenuChiledUnit.content_menu_unit_text_ja}
                                  </StyledContentListItemSpan>
                                  <StyledContentListItemLink href={contentMenuChiledUnit.content_menu_unit_path}/>
                                </StyledContentUnitListItemP>
                                </StyledContentUnitListItem>
                              );
                            })}
                          </StyledContentChildDiv>
                        );
                      })}
                    </StyledContentList>
                  );
                })}
              </Grid>
            </Grid>
          </ThemeProvider>
        </MainContentSubSmall>
      );
    }
  }

}

export default Index;