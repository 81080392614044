//-----------------------------------------------------------------------
// breadcrumbs
//
export interface Ibreadcrumb {
  key: string;
  path: string;
  text: string;
}

//-----------------------------------------------------------------------
// content menu
//
export interface IcontentMenuPrimary {
  content_menu_primary_key:     string;
  content_menu_primary_path:    string;
  content_menu_primary_text_en:  string;
  content_menu_primary_text_ja:  string;
  content_menu_secondary_array: IcontentMenuSecondary[];
}

export const initContentMenuPrimary = {
  content_menu_primary_key:     "",
  content_menu_primary_path:    "",
  content_menu_primary_text_en:  "",
  content_menu_primary_text_ja:  "",
  content_menu_secondary_array: []
}

export interface IcontentMenuSecondary {
  content_menu_secondary_key: string;
  content_menu_secondary_path: string;
  content_menu_secondary_text_en: string;
  content_menu_secondary_text_ja: string;
  content_menu_tertiary_array: IcontentMenuTertiary[];
}

export interface IcontentMenuTertiary {
  content_menu_tertiary_key: string;
  content_menu_tertiary_path: string;
  content_menu_tertiary_text_en: string;
  content_menu_tertiary_text_ja: string;
  content_menu_unit_array: IcontentMenuUnit[]
}

export interface IcontentMenuUnit {
  content_menu_unit_key: string;
  content_menu_unit_path: string;
  content_menu_unit_text_en: string;
  content_menu_unit_text_ja: string;
  content_menu_unit_description_en: string;
  content_menu_unit_description_ja: string;
}

export const initContentMenuUnit = {
  content_menu_unit_key: "",
  content_menu_unit_path: "",
  content_menu_unit_text_en: "",
  content_menu_unit_text_ja: "",
  content_menu_unit_description_en: "",
  content_menu_unit_description_ja: "",
}

export interface IcontentItemUnitInfoRoot
{
  content_item_unit_root_path: string;
  content_item_unit_root_data: IcontentItemUnitInfo[];
}

export interface IcontentItemUnitInfo {
  content_item_unit_key: string;
  content_item_unit_path: string;
  content_item_unit_text_en: string;
  content_item_unit_text_ja: string;
  content_item_unit_description_en: string;
  content_item_unit_description_ja: string;
  content_item_unit_data_cr_en: IcontentItemUnitData;
  content_item_unit_data_cr_ja: IcontentItemUnitData;
  content_item_unit_data_nc_en: IcontentItemUnitData;
  content_item_unit_data_nc_ja: IcontentItemUnitData;
}

export interface IcontentItemUnitData {
  content_item_unit_data: IcontentItemUnitDataItem[];
}

export interface IcontentItemUnitDataItem {
  content_item_unit_data_question_download_key: string;
  content_item_unit_data_answer_download_key: string;
  content_item_unit_data_question_url: string;
  content_item_unit_data_answer_url: string;
}

const initContentItemUnitDataItem = {
  content_item_unit_data_question_download_key: "",
  content_item_unit_data_answer_download_key: "",
  content_item_unit_data_question_url: "",
  content_item_unit_data_answer_url: "",
}

const initContentItemUnitData = {
  content_item_unit_data: [initContentItemUnitDataItem],
}

export const initContentItemUnitInfo = {
  content_item_unit_key: "",
  content_item_unit_path: "",
  content_item_unit_text_en: "",
  content_item_unit_text_ja: "",
  content_item_unit_description_en: "",
  content_item_unit_description_ja: "",
  content_item_unit_data_cr_en: initContentItemUnitData,
  content_item_unit_data_cr_ja: initContentItemUnitData,
  content_item_unit_data_nc_en: initContentItemUnitData,
  content_item_unit_data_nc_ja: initContentItemUnitData,
}

//-----------------------------------------------------------------------
// content
//
export interface IcontentNews {
  news_key:  string;
  news_title_en:  string;
  news_title_ja:  string;
  news_data_en: string[];
  news_data_ja: string[];
}

export interface IcontentTopics {
  topics_key:  string;
  topics_title_en:  string;
  topics_title_ja:  string;
  topics_data_en: string[];
  topics_data_ja: string[];
}
