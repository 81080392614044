import React from "react";
import { useParams } from "react-router-dom";
import { Grid } from '@mui/material';
import { ThemeProvider, styled } from "@mui/material/styles"

// local module
import { useAuth } from "../context/GlobalContext";
import { useWindowSize } from "../context/WindowSizeContext";
import { Ibreadcrumb, IcontentMenuPrimary, IcontentNews } from  "../type/ContentType";
import { globalTheme } from "../layout/GlobalStyle";
import { configPrimary } from "../config/primary";
import { configContent } from "../config/news";
import MainContent from "../layout/MainContent";
import MainContentSmall from "../layout/MainContentSmall";

//---------------------------------------------------------------------
// const
//
const defaultWidth = "100%";
const defaultHeight = "128px";

//-----------------------------------------------------------------------
// styled component (header)
//
const StyledHeaderContent = styled("div")(({ theme }) => ({
  // color
  backgroundColor: theme.palette.secondary.light,
  // size
  width: defaultWidth,
  height: defaultHeight,
  borderBottomLeftRadius: "8px",
  borderBottomRightRadius: "8px",
  // font / text
  display: "flex",
  alignItems: "center",
}));

const StyledHeaderTitle = styled("h4")(({ theme }) => ({
  // color
  color: theme.palette.primary.contrastText,
  // size
  margin: 0,
  paddingLeft: "1em",
  paddingRight: "1em",
  // font / text
  fontSize: "medium",
}));

const StyledContentRoot = styled("div")(({ theme }) => ({
  // size
  paddingLeft: 0,
  paddingRight: 0,
  paddingTop: "1.5em",
  paddingBottom: 0,
}));

const StyledContentText = styled("div")(({ theme }) => ({
  // color
  backgroundColor: theme.palette.secondary.light,
  color: theme.palette.primary.contrastText,
  // size
  paddingLeft: "0.5em",
  paddingRight: "0.5em",
  paddingTop: "0.2em",
  paddingBottom: "0.2em",
  // font / text
  border: "dashed 2px" + theme.palette.secondary.dark,
  borderRadius: "8px",
}));

//-----------------------------------------------------------------------
// styled component (content)
//
const StyledP = styled("p")(({ theme }) => ({
  // color
  color: theme.palette.primary.contrastText,
  // font / text
  fontSize: "small",
  padding: ".4em",
}));

const StyledSpanMediumTop = styled("h4")(({ theme }) => ({
  // color
  color: theme.palette.primary.contrastText,
  // size
  margin: 0,
  paddingLeft: 0,
  paddingRight: 0,
  paddingTop: ".5em",
  paddingBottom: ".5em",
  // font / text
  fontSize: "small",
}));

const StyledSpanMedium = styled("h4")(({ theme }) => ({
  // color
  color: theme.palette.primary.contrastText,
  // size
  margin: 0,
  paddingLeft: 0,
  paddingRight: 0,
  paddingTop: "2em",
  paddingBottom: ".5em",
  // font / text
  fontSize: "small",
}));

const StyledSpan = styled("span")(({ theme }) => ({
  // color
  color: theme.palette.primary.contrastText,
  // font / text
  fontSize: "small",
  padding: ".4em",
}));

//-----------------------------------------------------------------------
// function component
//
const News: React.FC = () => {

  //---------------------------------------------------------------------
  // context
  //
  const params = useParams();
  const {isEnglish} = useAuth();
  const [width, height] = useWindowSize();

  //---------------------------------------------------------------------
  // const
  //
  const contentMenuPrimaryArray: IcontentMenuPrimary[] = configPrimary;
  const contentArray: IcontentNews[] = configContent;

  const localContentKey = "news/" + params.content
  const localContent    = contentArray[contentArray.findIndex(item => item.news_key === localContentKey)];

  const localBreadcrumbs: Ibreadcrumb[] = [
    {
      key: "home",
      path: "/",
      text: isEnglish ? "HOME" : "ホーム",
    },
  ]

  const localNewsTitle = () => {
    if(isEnglish){
      return localContent.news_title_en.split(':')[localContent.news_title_en.split(':').length - 1].trim();
    }else{
      return localContent.news_title_ja.split(':')[localContent.news_title_ja.split(':').length - 1].trim();
    }
  }

  const localBreadcrumbLastItem: Ibreadcrumb = {
    key: localContentKey,
    path: "/" + localContentKey,
    text: localNewsTitle(),
  }

  //-----------------------------------------------------------------------
  // context
  //
  if(width > 1080){
    return (
      <MainContent
        mainContentBreadcrumbs={localBreadcrumbs}
        mainContentBreadcrumbLastItem={localBreadcrumbLastItem}
        contentMenuPrimaryArray={contentMenuPrimaryArray}
      >
        <ThemeProvider theme={globalTheme}>
          <Grid container>
            <Grid item xs={12}>
              <StyledHeaderContent>
                <StyledHeaderTitle>
                  {localNewsTitle()}
                </StyledHeaderTitle>
              </StyledHeaderContent>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12}>
              <StyledContentRoot>
                <StyledContentText>
                  <StyledP>
                    {isEnglish && localContent.news_data_en.map((item, index) => {
                      if(item[0] === '*'){
                        let tempItem = item.slice(1)
                        if(index === 0){
                          return(
                            <StyledSpanMediumTop>
                              {tempItem}
                            </StyledSpanMediumTop>
                          );
                        }else{
                          return(
                            <StyledSpanMedium>
                              {tempItem}
                            </StyledSpanMedium>
                          );
                        }
                      }else if(item[0] === '$'){
                        let tempItem = item.slice(1)
                        return(
                          <div>
                            <br/>
                            {tempItem}
                          </div>
                        );
                      }else{
                        return(
                          <div>
                            <br/>
                            {item}
                          </div>
                        );
                      }
                    })}
                    {!isEnglish && localContent.news_data_ja.map((item, index) => {
                      if(item[0] === '*'){
                        let tempItem = item.slice(1)
                        if(index === 0){
                          return(
                            <StyledSpanMediumTop>
                              {tempItem}
                            </StyledSpanMediumTop>
                          );
                        }else{
                          return(
                            <StyledSpanMedium>
                              {tempItem}
                            </StyledSpanMedium>
                          );
                        }
                      }else if(item[0] === '$'){
                        let tempItem = item.slice(1)
                        return(
                          <div>
                            <br/>
                            {tempItem}
                          </div>
                        );
                      }else{
                        return(
                          <div>
                            <br/>
                            {item}
                          </div>
                        );
                      }
                    })}
                  </StyledP>
                </StyledContentText>
              </StyledContentRoot>
            </Grid>
          </Grid>

        </ThemeProvider>
      </MainContent>
    );
  }else{
    return (
      <MainContentSmall
        mainContentBreadcrumbs={localBreadcrumbs}
        mainContentBreadcrumbLastItem={localBreadcrumbLastItem}
        contentMenuPrimaryArray={contentMenuPrimaryArray}
      >
        <ThemeProvider theme={globalTheme}>
          <Grid container>
            <Grid item xs={12}>
              <StyledHeaderContent>
                <StyledHeaderTitle>
                  {localNewsTitle()}
                </StyledHeaderTitle>
              </StyledHeaderContent>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12}>
              <StyledContentRoot>
                <StyledContentText>
                  <StyledP>
                    {isEnglish && localContent.news_data_en.map((item, index) => {
                      if(item[0] === '*'){
                        let tempItem = item.slice(1)
                        if(index === 0){
                          return(
                            <StyledSpanMediumTop>
                              {tempItem}
                            </StyledSpanMediumTop>
                          );
                        }else{
                          return(
                            <StyledSpanMedium>
                              {tempItem}
                            </StyledSpanMedium>
                          );
                        }
                      }else if(item[0] === '$'){
                        let tempItem = item.slice(1)
                        return(
                          <div>
                            <br/>
                            {tempItem}
                          </div>
                        );
                      }else{
                        return(
                          <div>
                            <br/>
                            {item}
                          </div>
                        );
                      }
                    })}
                    {!isEnglish && localContent.news_data_ja.map((item, index) => {
                      if(item[0] === '*'){
                        let tempItem = item.slice(1)
                        if(index === 0){
                          return(
                            <StyledSpanMediumTop>
                              {tempItem}
                            </StyledSpanMediumTop>
                          );
                        }else{
                          return(
                            <StyledSpanMedium>
                              {tempItem}
                            </StyledSpanMedium>
                          );
                        }
                      }else if(item[0] === '$'){
                        let tempItem = item.slice(1)
                        return(
                          <div>
                            <br/>
                            {tempItem}
                          </div>
                        );
                      }else{
                        return(
                          <div>
                            <br/>
                            {item}
                          </div>
                        );
                      }
                    })}
                  </StyledP>
                </StyledContentText>
              </StyledContentRoot>
            </Grid>
          </Grid>

        </ThemeProvider>
      </MainContentSmall>
    );
  }
}

export default News;