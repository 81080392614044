import React from "react";
import { Grid, Card, Typography, CardContent, CardActions, Button, Link } from '@mui/material';
import { styled } from "@mui/material/styles"

//-----------------------------------------------------------------------
// styled card
//
const StyledCard = styled(Card)(({ theme }) => ({
  // style
  border: "none",
  // size
  width: "auto",
  minHeight: "120px",
  paddingLeft: 0,
  paddingRight: 0,
  paddingTop: "1.5em",
  paddingBottom: 0,
}));

const StyledCardHeader = styled(Typography)(({ theme }) => ({
  // size
  width: "auto",
  height: "30px",
  // font / text
  display: "flex",
  alignItems: "center",
  padding: "0 8px",
}));

const StyledCardActions = styled(CardActions)(({ theme }) => ({
  padding: "0 4px",
}));

const StyledCardActionButtonRoot = styled("div")(({ theme }) => ({
  // size
  paddingLeft: ".5em",
  paddingRight: ".5em",
  paddingTop: ".5em",
  paddingBottom: ".5em",
}));

const StyledCardActionButton = styled(Button)(({ theme }) => ({
  // color
  backgroundColor: theme.palette.secondary.light,
  // size
  width: "100%",
  height: "70px",
  // font / text
  display: "flex",
  justifyContent: 'center',
}));

const StyledCardActionButtonMini = styled(Button)(({ theme }) => ({
  // color
  backgroundColor: theme.palette.secondary.light,
  // size
  width: "100%",
  height: "30px",
  // font / text
  display: "flex",
  justifyContent: 'center',
}));

const StyledCardText = styled(Typography)(({ theme }) => ({
  // size
  width: "auto",
  height: "50px",
  // font / text
  display: "flex",
  alignItems: "center",
}));

const StyledCardSpan = styled("span")(({ theme }) => ({
  // color
  color: theme.palette.primary.contrastText,
  // font / text
  fontSize: "small",
  padding: ".4em",
}));

const StyledCardP = styled("p")(({ theme }) => ({
  // color
  color: theme.palette.primary.contrastText,
  // font / text
  fontSize: "small",
  padding: ".4em",
}));

const StyledLink = styled(Link)(({ theme }) => ({
}));

interface StyledCardActionGridProps {
  children: React.FC;
}

//-----------------------------------------------------------------------
// props
//
interface Props {
  unitCardIsInglish: boolean;
  unitCardIndex: number;
  unitCardModalOpen: () => void;
  unitCardPageKeyRoot: string;
  unitCardPageUnit: string;
  unitCardQuestionDownloadkey: string;
  unitCardAnswerDownloadkey: string;
  unitCardQuestionUrl: string;
  unitCardAnswerUrl: string;
}

//-----------------------------------------------------------------------
// const
//
const getAlphabet = ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z",];

const getAlphabetGroup = ["A-I","J-R","S-Z",];

const getRomejiGroup = ["あ行","か行","さ行","た行","な行","は行","ま行","や行","ら行","わ行","が行","ざ行","だ行","ば行","ぱ行","きゃ行","しゃ行","ちゃ行","にゃ行","ひゃ行","みゃ行","りゃ行","ぎゃ行","じゃ行","びゃ行","ぴゃ行"];

const getRomejiGroupEn = ["a cols","ka cols","sa cols","ta cols","na cols","ha cols","ma cols","ya cols","ra cols","wa cols","ga cols","za cols","da cols","ba cols","pa cols","kya cols","sya cols","cha cols","nya cols","hya cols","mya cols","rya cols","gya cols","ja cols","bya cols","pya cols"];

const getNumberGroup = ["1","2","3","4","5","6","7","8","9","0",];

const getDocument = (tempKey:string, unitCardModalOpen:() => void) => {
  if(tempKey !== ""){
    //unitCardModalOpen();
    if(tempKey.startsWith("http")){
      window.open(tempKey, '_blank');
    }else{
      window.open("/" + tempKey, '_blank');
    }
  }
}

//-----------------------------------------------------------------------
// function component
//
const UnitCard: React.FC<Props> = ({unitCardIsInglish, unitCardIndex, unitCardModalOpen,
  unitCardPageKeyRoot, unitCardPageUnit, unitCardQuestionDownloadkey, unitCardAnswerDownloadkey, unitCardQuestionUrl, unitCardAnswerUrl}) => {
  //---------------------------------------------------------------------
  // main
  //
  if(unitCardPageKeyRoot == "/primary/english/alphabet"){
    if(unitCardPageUnit == "/01"){
      return (
        <StyledCard sx={{ maxWidth: "auto" }} variant="outlined">
          <StyledCardHeader>
            <StyledCardSpan>{unitCardIsInglish ? "Practice " + getAlphabet[unitCardIndex - 1] : getAlphabet[unitCardIndex - 1] + " の練習"}{}</StyledCardSpan>
          </StyledCardHeader>
          <StyledCardActions>
            <Grid container>
              <Grid item xs={12}>
                <StyledCardActionButtonRoot>
                  <StyledCardActionButton onClick={() => {getDocument(unitCardQuestionDownloadkey, unitCardModalOpen)}}>
                    <StyledCardText>
                      <StyledCardSpan>{unitCardIsInglish ? "Worksheet" : "練習プリント"}</StyledCardSpan>
                    </StyledCardText>
                  </StyledCardActionButton>
                </StyledCardActionButtonRoot>
              </Grid>
            </Grid>
          </StyledCardActions>
        </StyledCard>
      );
    }else if(unitCardPageUnit == "/02"){
      return (
        <StyledCard sx={{ maxWidth: "auto" }} variant="outlined">
          <StyledCardHeader>
            <StyledCardSpan>{unitCardIsInglish ? "Practice " + getAlphabetGroup[unitCardIndex - 1] : getAlphabetGroup[unitCardIndex - 1] + " の練習"}{}</StyledCardSpan>
          </StyledCardHeader>
          <StyledCardActions>
            <Grid container>
              <Grid item xs={12}>
                <StyledCardActionButtonRoot>
                  <StyledCardActionButton onClick={() => {getDocument(unitCardQuestionDownloadkey, unitCardModalOpen)}}>
                    <StyledCardText>
                      <StyledCardSpan>{unitCardIsInglish ? "Worksheet" : "練習プリント"}</StyledCardSpan>
                    </StyledCardText>
                  </StyledCardActionButton>
                </StyledCardActionButtonRoot>
              </Grid>
            </Grid>
          </StyledCardActions>
        </StyledCard>
      );
    }else{
      return (
        <StyledCard sx={{ maxWidth: "auto" }} variant="outlined">
          <StyledCardHeader>
            <StyledCardSpan>{unitCardIsInglish ? "No." + unitCardIndex : "第" + unitCardIndex + "回"}{}</StyledCardSpan>
          </StyledCardHeader>
          <StyledCardActions>
            <Grid container>
              <Grid item xs={12}>
                <StyledCardActionButtonRoot>
                  <StyledCardActionButton onClick={() => {getDocument(unitCardQuestionDownloadkey, unitCardModalOpen)}}>
                    <StyledCardText>
                      <StyledCardSpan>{unitCardIsInglish ? "Question" : "問題"}</StyledCardSpan>
                    </StyledCardText>
                  </StyledCardActionButton>
                </StyledCardActionButtonRoot>
              </Grid>
            </Grid>
          </StyledCardActions>
        </StyledCard>
      );
    }
  }else if(unitCardPageKeyRoot == "/primary/japanese/romeji"){
    if(unitCardPageUnit == "/01"){
      return (
        <StyledCard sx={{ maxWidth: "auto" }} variant="outlined">
          <StyledCardHeader>
            <StyledCardSpan>{unitCardIsInglish ? "Practice " + getRomejiGroupEn[unitCardIndex - 1] : getRomejiGroup[unitCardIndex - 1] + " の練習"}{}</StyledCardSpan>
          </StyledCardHeader>
          <StyledCardActions>
            <Grid container>
              <Grid item xs={12}>
                <StyledCardActionButtonRoot>
                  <StyledCardActionButton onClick={() => {getDocument(unitCardQuestionDownloadkey, unitCardModalOpen)}}>
                    <StyledCardText>
                      <StyledCardSpan>{unitCardIsInglish ? "Worksheet" : "練習プリント"}</StyledCardSpan>
                    </StyledCardText>
                  </StyledCardActionButton>
                </StyledCardActionButtonRoot>
              </Grid>
            </Grid>
          </StyledCardActions>
        </StyledCard>
      );
    }else{
      return (
        <StyledCard sx={{ maxWidth: "auto" }} variant="outlined">
          <StyledCardHeader>
            <StyledCardSpan>{unitCardIsInglish ? "No." + unitCardIndex : "第" + unitCardIndex + "回"}{}</StyledCardSpan>
          </StyledCardHeader>
          <StyledCardActions>
            <Grid container>
              <Grid item xs={12}>
                <StyledCardActionButtonRoot>
                  <StyledCardActionButton onClick={() => {getDocument(unitCardQuestionDownloadkey, unitCardModalOpen)}}>
                    <StyledCardText>
                      <StyledCardSpan>{unitCardIsInglish ? "Question" : "問題"}</StyledCardSpan>
                    </StyledCardText>
                  </StyledCardActionButton>
                </StyledCardActionButtonRoot>
              </Grid>
            </Grid>
          </StyledCardActions>
        </StyledCard>
      );
    }
  }else if(unitCardPageKeyRoot == "/early/number/practice"){
    if(unitCardPageUnit == "/01"){
      return (
        <StyledCard sx={{ maxWidth: "auto" }} variant="outlined">
          <StyledCardHeader>
            <StyledCardSpan>{unitCardIsInglish ? "Practice " + getNumberGroup[unitCardIndex - 1] : getNumberGroup[unitCardIndex - 1] + " のれんしゅう"}{}</StyledCardSpan>
          </StyledCardHeader>
          <StyledCardActions>
            <Grid container>
              <Grid item xs={12}>
                <StyledCardActionButtonRoot>
                  <StyledCardActionButton onClick={() => {getDocument(unitCardQuestionDownloadkey, unitCardModalOpen)}}>
                    <StyledCardText>
                      <StyledCardSpan>{unitCardIsInglish ? "Worksheet" : "れんしゅうプリント"}</StyledCardSpan>
                    </StyledCardText>
                  </StyledCardActionButton>
                </StyledCardActionButtonRoot>
              </Grid>
            </Grid>
          </StyledCardActions>
        </StyledCard>
      );
    }else{
      return (
        <StyledCard sx={{ maxWidth: "auto" }} variant="outlined">
          <StyledCardHeader>
            <StyledCardSpan>{unitCardIsInglish ? "No." + unitCardIndex : "第" + unitCardIndex + "回"}{}</StyledCardSpan>
          </StyledCardHeader>
          <StyledCardActions>
            <Grid container>
              <Grid item xs={12}>
                <StyledCardActionButtonRoot>
                  <StyledCardActionButton onClick={() => {getDocument(unitCardQuestionDownloadkey, unitCardModalOpen)}}>
                    <StyledCardText>
                      <StyledCardSpan>{unitCardIsInglish ? "Question" : "問題"}</StyledCardSpan>
                    </StyledCardText>
                  </StyledCardActionButton>
                </StyledCardActionButtonRoot>
              </Grid>
            </Grid>
          </StyledCardActions>
        </StyledCard>
      );
    }
  }else if(unitCardPageKeyRoot == "/early/maze/practice"){
    if(unitCardPageUnit == "/01"){
      return (
        <StyledCard sx={{ maxWidth: "auto" }} variant="outlined">
          <StyledCardHeader>
            <StyledCardSpan>{unitCardIsInglish ? "No." + unitCardIndex : "だい" + unitCardIndex + "かい"}{}</StyledCardSpan>
          </StyledCardHeader>
          <StyledCardActions>
            <Grid container>
              <Grid item xs={12}>
                <StyledCardActionButtonRoot>
                  <StyledCardActionButton onClick={() => {getDocument(unitCardQuestionDownloadkey, unitCardModalOpen)}}>
                    <StyledCardText>
                      <StyledCardSpan>{unitCardIsInglish ? "Worksheet" : "れんしゅうプリント"}</StyledCardSpan>
                    </StyledCardText>
                  </StyledCardActionButton>
                </StyledCardActionButtonRoot>
              </Grid>
            </Grid>
          </StyledCardActions>
        </StyledCard>
      );
    }else{
      return (
        <StyledCard sx={{ maxWidth: "auto" }} variant="outlined">
          <StyledCardHeader>
            <StyledCardSpan>{unitCardIsInglish ? "No." + unitCardIndex : "だい" + unitCardIndex + "かい"}{}</StyledCardSpan>
          </StyledCardHeader>
          <StyledCardActions>
            <Grid container>
              <Grid item xs={12}>
                <StyledCardActionButtonRoot>
                  <StyledCardActionButton onClick={() => {getDocument(unitCardQuestionDownloadkey, unitCardModalOpen)}}>
                    <StyledCardText>
                      <StyledCardSpan>{unitCardIsInglish ? "Worksheet" : "れんしゅうプリント"}</StyledCardSpan>
                    </StyledCardText>
                  </StyledCardActionButton>
                </StyledCardActionButtonRoot>
              </Grid>
            </Grid>
          </StyledCardActions>
        </StyledCard>
      );
    }
  }else if(unitCardPageKeyRoot == "/digital/programming/scratch"){
    if(unitCardPageUnit == "/01"){
      return (
        <StyledCard sx={{ maxWidth: "auto" }} variant="outlined">
          <StyledCardHeader>
            <StyledCardSpan>{unitCardIsInglish ? "No." + unitCardIndex : "第" + unitCardIndex + "回"}{}</StyledCardSpan>
          </StyledCardHeader>
          <StyledCardActions>
            <Grid container>
              <Grid item xs={12}>
                <StyledCardActionButtonRoot>
                  <StyledCardActionButtonMini onClick={() => {getDocument(unitCardQuestionDownloadkey, unitCardModalOpen)}}>
                    <StyledCardText>
                      <StyledCardSpan>{unitCardIsInglish ? "Worksheet" : "解説プリント"}</StyledCardSpan>
                    </StyledCardText>
                  </StyledCardActionButtonMini>
                </StyledCardActionButtonRoot>
              </Grid>
              <Grid item xs={6}>
                <StyledCardActionButtonRoot>
                  <StyledCardActionButtonMini onClick={() => {getDocument(unitCardQuestionUrl, unitCardModalOpen)}}>
                    <StyledCardText>
                      <StyledCardSpan>{unitCardIsInglish ? "Question" : "問題"}</StyledCardSpan>
                    </StyledCardText>
                  </StyledCardActionButtonMini>
                </StyledCardActionButtonRoot>
              </Grid>
              <Grid item xs={6}>
                <StyledCardActionButtonRoot>
                  <StyledCardActionButtonMini onClick={() => {getDocument(unitCardAnswerUrl, unitCardModalOpen)}}>
                    <StyledCardText>
                      <StyledCardSpan>{unitCardIsInglish ? "Answer" : "答え"}</StyledCardSpan>
                    </StyledCardText>
                  </StyledCardActionButtonMini>
                </StyledCardActionButtonRoot>
              </Grid>
            </Grid>
          </StyledCardActions>
        </StyledCard>
      );
    }else{
      return (
        <StyledCard sx={{ maxWidth: "auto" }} variant="outlined">
          <StyledCardHeader>
            <StyledCardSpan>{unitCardIsInglish ? "No." + unitCardIndex : "第" + unitCardIndex + "回"}{}</StyledCardSpan>
          </StyledCardHeader>
          <StyledCardActions>
            <Grid container>
              <Grid item xs={12}>
                <StyledCardActionButtonRoot>
                  <StyledCardActionButton onClick={() => {getDocument(unitCardQuestionDownloadkey, unitCardModalOpen)}}>
                    <StyledCardText>
                      <StyledCardSpan>{unitCardIsInglish ? "Worksheet" : "解説プリント"}</StyledCardSpan>
                    </StyledCardText>
                  </StyledCardActionButton>
                </StyledCardActionButtonRoot>
              </Grid>
            </Grid>
          </StyledCardActions>
        </StyledCard>
      );
    }
  }else{
    return (
      <StyledCard sx={{ maxWidth: "auto" }} variant="outlined">
        <StyledCardHeader>
          <StyledCardSpan>{unitCardIsInglish ? "No." + unitCardIndex : "第" + unitCardIndex + "回"}{}</StyledCardSpan>
        </StyledCardHeader>
        <StyledCardActions>
          <Grid container>
            <Grid item xs={6}>
              <StyledCardActionButtonRoot>
                <StyledCardActionButton onClick={() => {getDocument(unitCardQuestionDownloadkey, unitCardModalOpen)}}>
                  <StyledCardText>
                    <StyledCardSpan>{unitCardIsInglish ? "Question" : "問題"}</StyledCardSpan>
                  </StyledCardText>
                </StyledCardActionButton>
              </StyledCardActionButtonRoot>
            </Grid>
            <Grid item xs={6}>
              <StyledCardActionButtonRoot>
                <StyledCardActionButton onClick={() => {getDocument(unitCardAnswerDownloadkey, unitCardModalOpen)}}>
                  <StyledCardText>
                    <StyledCardSpan>{unitCardIsInglish ? "Answer" : "答え"}</StyledCardSpan>
                  </StyledCardText>
                </StyledCardActionButton>
              </StyledCardActionButtonRoot>
            </Grid>
          </Grid>
        </StyledCardActions>
      </StyledCard>
    );
  }

}

export default UnitCard;