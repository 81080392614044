import * as React from "react";
import { Grid, List, Breadcrumbs, Link } from '@mui/material';
import { ThemeProvider, styled } from "@mui/material/styles";

// local module
import { useAuth } from "../context/GlobalContext";
import { Ibreadcrumb, IcontentMenuPrimary } from  "../type/ContentType";
import { globalTheme } from "./GlobalStyle";
import GlobalHeaderSmall from "./GlobalHeaderSmall";
import MainHeaderSmall from "./MainHeaderSmall";

//-----------------------------------------------------------------------
// const
//
const breadcrumbsFontSize = "xx-small";
const breadcrumbsPadding  = "0.4m";

const breadcrumbsHeight = "24px";

//-----------------------------------------------------------------------
// styled component (area)
//
const StyledContent = styled("div")(({ theme }) => ({
  // backgroung
  backgroundColor: theme.palette.primary.light,
  // content
  display: "flex",
}));

const StyledBreadcrumbsDiv = styled("div")(({ theme }) => ({
  // backgroung
  backgroundColor: theme.palette.secondary.main,
  // size
  height: breadcrumbsHeight,
  // content
  display: "flex",
  alignItems: "center",
}));

const StyledSideMenuList = styled(List)(({ theme }) => ({
}));

const StyledSideMenuSubHeaderDiv = styled("div")(({ theme }) => ({
  // backgroung
  backgroundImage: "radial-gradient(#f74545 0.5px, #e3b3ce 0.5px)",
  backgroundSize: "10px 10px",
  opacity: 1,
  // size
  height: "75px",
  // content
  position: "relative",
  fontSize: "small",
}));

const StyledSpacer = styled("div")(({ theme }) => ({
  width: '10px'
}));

const StyledBreadcrumbsSpacer = styled("div")(({ theme }) => ({
  // size
  width: '10px'
}));

//-----------------------------------------------------------------------
// styled component (breadcrums content)
//
const StyledBreadcrumbs = styled(Breadcrumbs)(({ theme }) => ({
  // text
  color: theme.palette.primary.contrastText,
  fontSize: breadcrumbsFontSize,
  padding: breadcrumbsPadding,
}));

const StyledBreadcrumbsLink = styled(Link)(({ theme }) => ({
  // text
  color: theme.palette.primary.contrastText,
  fontSize: breadcrumbsFontSize,
  padding: breadcrumbsPadding,
}));

const StyledBreadcrumbsSpan = styled("span")(({ theme }) => ({
  // text
  color: theme.palette.primary.contrastText,
  fontSize: breadcrumbsFontSize,
  padding: breadcrumbsPadding,
}));

//-----------------------------------------------------------------------
// styled component (sidemenu content)
//
const StyledSideMenuSubHeaderImage = styled("img")(({ theme }) => ({
  // position
  position: "absolute",
  bottom: "5px",
  right: "5px",
  // size
  height: "50px",
  width: "50px",
}));

const StyledSideMenuSubHeaderLink = styled("a")(({ theme }) => ({
  // position
  display: "block",
  position: "absolute",
  top: "0",
  left: "0",
  width: "100%",
  height: "100%",
}));

const StyledSideMenuSubHeaderSpan = styled("span")(({ theme }) => ({
  // position
  position: "absolute",
  top:"50%",
  left:"0",
  transform:"translate(0%, -50%)",
  // size
  width:"100%",
  // color
  color: theme.palette.primary.contrastText,
  // font / text
  fontSize: "small",
  padding: ".4em",
}));

const StyledSideMenuListItemDiv = styled("div")(({ theme }) => ({
  // backgroung
  backgroundColor: theme.palette.secondary.light,
  // size
  height: "50px",
  // content
  position: "relative",
  fontSize: "small",
}));

const StyledSideMenuListItemLink = styled("a")(({ theme }) => ({
  // position
  display: "block",
  position: "absolute",
  top: "0",
  left: "0",
  width: "100%",
  height: "100%",
}));

const StyledSideMenuSpan = styled("span")(({ theme }) => ({
  // position
  display: "flex",
  alignItems: "center",
  height: "100%",
  // color
  color: theme.palette.primary.contrastText,
  // font / text
  fontSize: "small",
  paddingLeft: ".4em",
}));

//-----------------------------------------------------------------------
// props
//
interface Props {
  mainContentBreadcrumbs: Ibreadcrumb[];
  mainContentBreadcrumbLastItem: Ibreadcrumb;
  contentMenuPrimaryArray: IcontentMenuPrimary[];
  children: React.ReactNode;
}

//-----------------------------------------------------------------------
// function component
//
const MainContentSubSmall: React.FC<Props> = ({mainContentBreadcrumbs, mainContentBreadcrumbLastItem, contentMenuPrimaryArray, children}) =>  {

  //---------------------------------------------------------------------
  // const
  //
  const {isEnglish} = useAuth();

  //---------------------------------------------------------------------
  // main
  //
  return (
    <GlobalHeaderSmall>
      <MainHeaderSmall>
        <ThemeProvider theme={globalTheme}>
          <StyledContent>
            <Grid container>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12}>
                    <StyledBreadcrumbsDiv>
                      <StyledBreadcrumbsSpacer/>
                      <StyledBreadcrumbs separator=">" aria-label="breadcrumb">
                        {mainContentBreadcrumbs.map((breadcrumb) => {
                          return(
                            <StyledBreadcrumbsLink underline="hover" key={breadcrumb.key} href={breadcrumb.path}>
                              {breadcrumb.text}
                            </StyledBreadcrumbsLink>
                          )
                        })}
                        <StyledBreadcrumbsSpan>
                          {mainContentBreadcrumbLastItem.text}
                        </StyledBreadcrumbsSpan>
                      </StyledBreadcrumbs>
                    </StyledBreadcrumbsDiv>
                    {children}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </StyledContent>
        </ThemeProvider>
      </MainHeaderSmall>
    </GlobalHeaderSmall>
  );
}

export default MainContentSubSmall;
