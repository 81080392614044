import { createTheme } from "@mui/material/styles";

//-----------------------------------------------------------------------
// style
//
export const globalThemeDefault = createTheme({
  // palette
  palette: {
    mode: "light",
    primary: {
      main: "#95CFC2",
      light: "#B5DDD4",
      dark: "#6FBDAB"
    },
    secondary: {
      main: "#e5c6c3",
      light: "#F4E7E6",
      dark: "#C5817B"
    },
    info: {
      main: "#edce7a",
      light: "#F2DCA5",
      dark: "#E0A91E"
    },
    success: {
      main: "#9ac5e5",
      light: "#C2DCEF",
      dark: "#4897CF"
    },
    error: {
      main: "#c98c9a",
      light: "#E8CED4",
      dark: "#9F4B5E"
    },
  },
  // font-family
  typography: {
    fontFamily: ["Verdana", "Hiragino Sans"].join(","),
    fontSize: 24
  },
})

export const globalThemeSpring = createTheme({
  // palette
  palette: {
    mode: "light",
    primary: {
      main: "#FFFBC1",
      light: "#FFFDEF",
      dark: "#FFF789"
    },
    secondary: {
      main: "#FEBE8C",
      light: "#FED9BD",
      dark: "#FD9F58"
    },
    info: {
      main: "#F7A4A4",
      light: "#FAD0D0",
      dark: "#F17373"
    },
    success: {
      main: "#B6E2A1",
      light: "#D2ECC4",
      dark: "#96D477"
    },
    error: {
      main: "#DC3535",
      light: "#E25E5E",
      dark: "#BA2020"
    },
  },
  // font-family
  typography: {
    fontFamily: ["Verdana", "Hiragino Sans"].join(","),
    fontSize: 24
  },
})

export const globalThemeSummer = createTheme({
  // palette
  palette: {
    mode: "light",
    primary: {
      main: "#FFEDDA",
      light: "#FFFFFF",
      dark: "#FFD1A3"
    },
    secondary: {
      main: "#FFE898",
      light: "#FFF1C6",
      dark: "#FFDA60"
    },
    info: {
      main: "#F7AAAA",
      light: "#FBD9D9",
      dark: "#F47C7C"
    },
    success: {
      main: "#6BC6FF",
      light: "#9ED9FF",
      dark: "#3DB2FF"
    },
    error: {
      main: "#6BC6FF",
      light: "#E25E5E",
      dark: "#BA2020"
    },
  },
  // font-family
  typography: {
    fontFamily: ["Verdana", "Hiragino Sans"].join(","),
    fontSize: 24
  },
})

export const globalThemeAutumn = createTheme({
  // palette
  palette: {
    mode: "light",
    primary: {
      main: "#DFD8CA",
      light: "#F1EEE8",
      dark: "#CABFA9"
    },
    secondary: {
      main: "#C3ABAB",
      light: "#D6C6C6",
      dark: "#AB8B8B"
    },
    info: {
      main: "#FBF3E4",
      light: "#FFFFFF",
      dark: "#F3DDB3"
    },
    success: {
      main: "#9B7272",
      light: "#AE8D8D",
      dark: "#7D5858"
    },
    error: {
      main: "#6BC6FF",
      light: "#E25E5E",
      dark: "#BA2020"
    },
  },
  // font-family
  typography: {
    fontFamily: ["Verdana", "Hiragino Sans"].join(","),
    fontSize: 24
  },
})

export const globalThemeWinter = createTheme({
  // palette
  palette: {
    mode: "light",
    primary: {
      main: "#F2F2F2",
      light: "#FFFFFF",
      dark: "#D6D6D6"
    },
    secondary: {
      main: "#66BFBF",
      light: "#88CDCD",
      dark: "#46A8A8"
    },
    info: {
      main: "#F7AAAA",
      light: "#FBD9D9",
      dark: "#F47C7C"
    },
    success: {
      main: "#764AF1",
      light: "#9576F3",
      dark: "#3C0FC1"
    },
    error: {
      main: "#6BC6FF",
      light: "#E25E5E",
      dark: "#BA2020"
    },
  },
  // font-family
  typography: {
    fontFamily: ["Verdana", "Hiragino Sans"].join(","),
    fontSize: 24
  },
})

export const globalTheme = createTheme({
  // palette
  palette: {
    mode: "light",
    primary: {
      main: "#F2F2F2",
      light: "#FFFFFF",
      dark: "#D6D6D6"
    },
    secondary: {
      main: "#F3B7AD",
      light: "#F9DBD7",
      dark: "#EB8C7E"
    },
    info: {
      main: "#FBD0E0",
      light: "#FCE7F0",
      dark: "#F272A3"
    },
    success: {
      main: "#F6C445",
      light: "#F7D172",
      dark: "#F2B211"
    },
    error: {
      main: "#6BC6FF",
      light: "#E25E5E",
      dark: "#BA2020"
    },
  },
  // font-family
  typography: {
    fontFamily: ["Verdana", "Hiragino Sans"].join(","),
    fontSize: 24
  },
})