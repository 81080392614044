//-----------------------------------------------------------------------
// common const
//
export const stub = (): never => {
  throw new Error(
    "Context initialize Error."
  );
}

//-----------------------------------------------------------------------
// auth context
//
export interface Iuser {
  userid:   string;
  username: string;
}

export const iniUser: Iuser = {
  userid:    "",
  username : "",
}

export interface IsignInOption {
  username: string;
  password: string;
}

export const iniSignInOption: IsignInOption = {
  username: "",
  password: "",
}

export interface IconfirmSignInOption {
  idtoken : string;
}

export const iniConfirmSignInOption: IconfirmSignInOption = {
  idtoken : "",
}

export interface IsignOutOption {
  idtoken : string;
}

export const inisignOutOption: IsignOutOption = {
  idtoken : "",
}

export interface IAuthState {
  isAuthenticated : boolean;
  isLoading       : boolean;
  isEnglish       : boolean;
  user            : Iuser;
}

export const iniAuthState: IAuthState = {
  isAuthenticated : false,
  isLoading       : false,
  isEnglish       : false,
  user            : iniUser,
}

export interface IAuthContext extends IAuthState{
  setLanguage        : () => void;
  setTracking        : (path: string) => void;
}

export const iniIAuthContext: IAuthContext = {
  ...iniAuthState,
  setLanguage        : stub,
  setTracking        : stub,
}
