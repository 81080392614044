import React from "react";
import { Grid, Link } from '@mui/material';
import { ThemeProvider, styled } from "@mui/material/styles"

// local module
import { useAuth } from "../context/GlobalContext";
import { useWindowSize } from "../context/WindowSizeContext";
import { Ibreadcrumb, IcontentMenuPrimary } from  "../type/ContentType";
import { globalTheme } from "../layout/GlobalStyle";
import { configPrimary } from "../config/primary";
import { configContent } from "../config/news";
import MainContent from "../layout/MainContent";
import MainContentSmall from "../layout/MainContentSmall";

//---------------------------------------------------------------------
// const
//
const defaultWidth = "100%";
const defaultHeight = "128px";

//-----------------------------------------------------------------------
// const message system
//
const labelRecommendEn = "◆ RECOMMEND";
const labelRecommendJa = "◆ きょうのおすすめ";

const labelNoticeEn    = "◆ NOTICE";
const labelNoticeJa    = "◆ お知らせ";

class globalMessageSystemClass {
  labelRecommend: string;
  labelNotice:    string;
  constructor(
    labelRecommend: string,
    labelNotice:    string
  ) {
    this.labelRecommend = labelRecommend;
    this.labelNotice    = labelNotice;
  }
}

const globalMessageSystem = (language: boolean): globalMessageSystemClass => {
  if(language){
    return new globalMessageSystemClass(
      labelRecommendEn,
      labelNoticeEn
    );
  }else{
    return new globalMessageSystemClass(
      labelRecommendJa,
      labelNoticeJa
    );
  }
}

//-----------------------------------------------------------------------
// styled component (header)
//
const StyledHeaderContent = styled("div")(({ theme }) => ({
  // color
  backgroundColor: theme.palette.secondary.light,
  // size
  width: defaultWidth,
  height: defaultHeight,
  borderBottomLeftRadius: "8px",
  borderBottomRightRadius: "8px",
  // font / text
  display: "flex",
  alignItems: "center",
}));

const StyledContentRoot = styled("div")(({ theme }) => ({
  // size
  paddingLeft: 0,
  paddingRight: 0,
  paddingTop: "1.5em",
  paddingBottom: 0,
}));

const StyledContentTitle = styled("h4")(({ theme }) => ({
  // color
  color: theme.palette.primary.contrastText,
  // size
  margin: 0,
  paddingBottom: ".5em",
  // font / text
  fontSize: "medium",
}));

const StyledContentText = styled("div")(({ theme }) => ({
  // color
  backgroundColor: theme.palette.secondary.light,
  color: theme.palette.primary.contrastText,
  // size
  paddingLeft: "0.5em",
  paddingRight: "0.5em",
  paddingTop: "0.2em",
  paddingBottom: "0.2em",
  // font / text
  border: "dashed 2px" + theme.palette.secondary.dark,
  borderRadius: "8px",
}));

//-----------------------------------------------------------------------
// styled component (content)
//
const StyledHeaderP = styled("p")(({ theme }) => ({
  // color
  color: theme.palette.primary.contrastText,
  // size
  margin: 0,
  paddingLeft: "1em",
  paddingRight: "1em",
  // font / text
  fontSize: "medium",
}));

const StyledP = styled("p")(({ theme }) => ({
  // color
  color: theme.palette.primary.contrastText,
  // font / text
  fontSize: "small",
  padding: ".4em",
}));

const StyledHeaderSpan = styled("span")(({ theme }) => ({
  fontWeight: "bold",
}));

const StyledSpan = styled("span")(({ theme }) => ({
  // color
  color: theme.palette.primary.contrastText,
  // font / text
  fontSize: "small",
  padding: ".4em",
}));

const StyledSpanNews = styled("span")(({ theme }) => ({
  // color
  color: theme.palette.primary.contrastText,
  // font / text
  display: "block",
  fontSize: "small",
  padding: ".4em",
}));

const StyledLink = styled(Link)(({ theme }) => ({
}));

//-----------------------------------------------------------------------
// function component
//
const Index: React.FC = () => {

  //---------------------------------------------------------------------
  // context
  //
  const {isEnglish} = useAuth();
  const [width, height] = useWindowSize();

  //---------------------------------------------------------------------
  // const
  //
  const contentMenuPrimaryArray: IcontentMenuPrimary[] = configPrimary;

  const localBreadcrumbs: Ibreadcrumb[] = [
  ]

  const localBreadcrumbLastItem: Ibreadcrumb = {
    key: "home",
    path: "/",
    text: isEnglish ? "HOME" : "ホーム",
  }

  const localNews = configContent.sort((a, b) => {return a.news_key > b.news_key ? -1 : 1;});

  //---------------------------------------------------------------------
  // const
  //

  //-----------------------------------------------------------------------
  // context
  //
  if(width > 1080){
    return (
      <MainContent
        mainContentBreadcrumbs={localBreadcrumbs}
        mainContentBreadcrumbLastItem={localBreadcrumbLastItem}
        contentMenuPrimaryArray={contentMenuPrimaryArray}
      >
        <ThemeProvider theme={globalTheme}>
          <Grid container>
            <Grid item xs={12}>
              <StyledHeaderContent>
                <StyledHeaderP>
                  {isEnglish  && "Thank you for visiting our web site."}
                  {isEnglish  && <br/>}
                  {isEnglish  && <StyledHeaderSpan>WORKSHEET LABO</StyledHeaderSpan>}
                  {isEnglish  && " will support your lernig at home."}
                  {isEnglish  && <br/>}
                  {isEnglish  && "You can download and printout all worksheets on our web site "}
                  {isEnglish  && <StyledHeaderSpan>for free</StyledHeaderSpan>}
                  {isEnglish  && "."}

                  {!isEnglish && <StyledHeaderSpan>プリントらぼ</StyledHeaderSpan>}
                  {!isEnglish && " はおうち学習のサポートを目的としたウェブサイトです。"}
                  {!isEnglish && <br/>}
                  {!isEnglish && "本サイトに掲載されている学習プリントは、"}
                  {!isEnglish && <StyledHeaderSpan>全て無料</StyledHeaderSpan>}
                  {!isEnglish && " でダウンロードできます。"}
                </StyledHeaderP>
              </StyledHeaderContent>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12}>
              <StyledContentRoot>
                <StyledContentTitle>
                  {globalMessageSystem(isEnglish).labelRecommend}
                </StyledContentTitle>
                <StyledContentText>
                  <StyledP>
                    {isEnglish  && ">> recommended worksheet"}
                    {!isEnglish && ">> おすすめプリント"}
                  </StyledP>
                </StyledContentText>
              </StyledContentRoot>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12}>
              <StyledContentRoot>
                <StyledContentTitle>
                  {globalMessageSystem(isEnglish).labelNotice}
                </StyledContentTitle>
                <StyledContentText>
                  <StyledP>
                    {localNews.map((item) => {
                      return(
                        <StyledLink href={"/" + item.news_key}>
                          <StyledSpanNews>
                            {isEnglish  && ">> " + item.news_title_en}
                            {!isEnglish && ">> " + item.news_title_ja}
                          </StyledSpanNews>
                        </StyledLink>
                      )
                    })}
                  </StyledP>
                </StyledContentText>
              </StyledContentRoot>
            </Grid>
          </Grid>

        </ThemeProvider>
      </MainContent>
    );
  }else{
    return (
      <MainContentSmall
        mainContentBreadcrumbs={localBreadcrumbs}
        mainContentBreadcrumbLastItem={localBreadcrumbLastItem}
        contentMenuPrimaryArray={contentMenuPrimaryArray}
      >
        <ThemeProvider theme={globalTheme}>
          <Grid container>
            <Grid item xs={12}>
              <StyledHeaderContent>
                <StyledHeaderP>
                  {isEnglish  && "Thank you for visiting our web site."}
                  {isEnglish  && <br/>}
                  {isEnglish  && <StyledHeaderSpan>WORKSHEET LABO</StyledHeaderSpan>}
                  {isEnglish  && " will support your lernig at home."}
                  {isEnglish  && <br/>}
                  {isEnglish  && "You can download and printout all worksheets on our web site "}
                  {isEnglish  && <StyledHeaderSpan>for free</StyledHeaderSpan>}
                  {isEnglish  && "."}

                  {!isEnglish && <StyledHeaderSpan>プリントらぼ</StyledHeaderSpan>}
                  {!isEnglish && " はおうち学習のサポートを目的としたウェブサイトです。"}
                  {!isEnglish && <br/>}
                  {!isEnglish && "本サイトに掲載されている学習プリントは、"}
                  {!isEnglish && <StyledHeaderSpan>全て無料</StyledHeaderSpan>}
                  {!isEnglish && " でダウンロードできます。"}
                </StyledHeaderP>
              </StyledHeaderContent>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12}>
              <StyledContentRoot>
                <StyledContentTitle>
                  {globalMessageSystem(isEnglish).labelRecommend}
                </StyledContentTitle>
                <StyledContentText>
                  <StyledP>
                    {isEnglish  && ">> recommended worksheet"}
                    {!isEnglish && ">> おすすめプリント"}
                  </StyledP>
                </StyledContentText>
              </StyledContentRoot>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12}>
              <StyledContentRoot>
                <StyledContentTitle>
                  {globalMessageSystem(isEnglish).labelNotice}
                </StyledContentTitle>
                <StyledContentText>
                  <StyledP>
                    {localNews.map((item) => {
                      return(
                        <StyledLink href={"/" + item.news_key}>
                          <StyledSpanNews>
                            {isEnglish  && ">> " + item.news_title_en}
                            {!isEnglish && ">> " + item.news_title_ja}
                          </StyledSpanNews>
                        </StyledLink>
                      )
                    })}
                  </StyledP>
                </StyledContentText>
              </StyledContentRoot>
            </Grid>
          </Grid>

        </ThemeProvider>
      </MainContentSmall>
    );
  }
}

export default Index;