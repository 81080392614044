import React from "react";
import { useCookies  } from "react-cookie";
import ReactGA from "react-ga4";
import {Iuser, iniUser, IAuthContext, iniIAuthContext} from  "../type/GlobalType";

//-----------------------------------------------------------------------
// auth context
//
const AuthContext = React.createContext<IAuthContext>(iniIAuthContext);
export const useAuth = () => React.useContext(AuthContext);
export interface IAuthContextProviderParams {
    children: React.ReactNode;
}

//-----------------------------------------------------------------------
// auth provider
//
export const AuthContextProvider = (props: IAuthContextProviderParams) => {

  //---------------------------------------------------------------------
  // context
  //
  const [cookies, setCookie] = useCookies(["language"]);
  const [isEnglish, setIsEnglish] = React.useState<boolean>((cookies.language == "en"));
  const [isAuthenticated, setIsAuthenticated] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [user, setUser] = React.useState<Iuser>(iniUser);

  //---------------------------------------------------------------------
  // method
  //
  const setLanguage = (): void => {
    setIsEnglish(!isEnglish);
    var expDate = new Date();
    expDate.setDate(expDate.getDate() + 365);
    setCookie("language", (!isEnglish ? "en" : "ja"), {expires: expDate, path: '/'});
  }

  const setTracking = (path: string): void => {
    ReactGA.initialize("G-6LX59BBY24");
    ReactGA.send({
      hitType: "pageview",
      page: path,
    });
  }

  //---------------------------------------------------------------------
  // main
  //
  return (
    <AuthContext.Provider value={{
      isAuthenticated,
      isLoading,
      isEnglish,
      user,
      setLanguage,
      setTracking,
    }}>{props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;