import React from "react";
import { useParams } from "react-router-dom";
import { Grid, Pagination, PaginationItem, Modal, Box} from '@mui/material';
import { ThemeProvider, styled } from "@mui/material/styles"

// local module
import { useAuth } from "../context/GlobalContext";
import { useWindowSize } from "../context/WindowSizeContext";
import { Ibreadcrumb,
         IcontentMenuPrimary, initContentMenuPrimary,
         IcontentItemUnitInfo, initContentItemUnitInfo,
         IcontentItemUnitInfoRoot, IcontentItemUnitDataItem } from  "../type/ContentType";
import { globalTheme } from "../layout/GlobalStyle";
import { configPrimary } from "../config/primary";
import { configUnitInfo } from "../config/unit/info";
import MainContent from "../layout/MainContent";
import MainContentSubSmall from "../layout/MainContentSubSmall";
import UnitCard from  "../component/UnitCard";

// image
import ContentImageEarly from '../image/char_early_03.png';
import ContentImagePrimary from '../image/char_primary_03.png';
import ContentImageDigital from '../image/char_digital_03.png';

// #region fields

import SampleImageDefaultEn from '../image/sample_x01_mental_en.png';
import SampleImageDefaultJa from '../image/sample_x01_mental_ja.png';

import SampleImageDigitalProgrammingScratch01En from '../image/sample/digital/programming/scratch/sample_x01_digital_programming_scratch_01_en.png';
import SampleImageDigitalProgrammingScratch01Ja from '../image/sample/digital/programming/scratch/sample_x01_digital_programming_scratch_01_ja.png';

import SampleImageEarlyNumberPracticet01En from '../image/sample/early/number/practice/sample_x01_early_number_practice_01_en.png';
import SampleImageEarlyNumberPracticet01Ja from '../image/sample/early/number/practice/sample_x01_early_number_practice_01_ja.png';

import SampleImageEarlyMazePracticet01En from '../image/sample/early/maze/practice/sample_x01_early_maze_practice_01_en.png';
import SampleImageEarlyMazePracticet01Ja from '../image/sample/early/maze/practice/sample_x01_early_maze_practice_01_ja.png';

import SampleImagePrimaryJapaneseRomeji01En from '../image/sample/primary/japanese/romeji/sample_x01_primary_japanese_romeji_01_en.png';
import SampleImagePrimaryJapaneseRomeji01Ja from '../image/sample/primary/japanese/romeji/sample_x01_primary_japanese_romeji_01_ja.png';

import SampleImagePrimaryEnglishAlphabet01En from '../image/sample/primary/english/alphabet/sample_x01_primary_english_alphabet_01_en.png';
import SampleImagePrimaryEnglishAlphabet01Ja from '../image/sample/primary/english/alphabet/sample_x01_primary_english_alphabet_01_ja.png';
import SampleImagePrimaryEnglishAlphabet02En from '../image/sample/primary/english/alphabet/sample_x01_primary_english_alphabet_02_en.png';
import SampleImagePrimaryEnglishAlphabet02Ja from '../image/sample/primary/english/alphabet/sample_x01_primary_english_alphabet_02_ja.png';

import SampleImagePrimaryMathAdd01En from '../image/sample/primary/math/add/sample_x01_primary_math_add_01_en.png';
import SampleImagePrimaryMathAdd01Ja from '../image/sample/primary/math/add/sample_x01_primary_math_add_01_ja.png';
import SampleImagePrimaryMathAdd02En from '../image/sample/primary/math/add/sample_x01_primary_math_add_02_en.png';
import SampleImagePrimaryMathAdd02Ja from '../image/sample/primary/math/add/sample_x01_primary_math_add_02_ja.png';
import SampleImagePrimaryMathAdd03En from '../image/sample/primary/math/add/sample_x01_primary_math_add_03_en.png';
import SampleImagePrimaryMathAdd03Ja from '../image/sample/primary/math/add/sample_x01_primary_math_add_03_ja.png';

import SampleImagePrimaryMathSub01En from '../image/sample/primary/math/sub/sample_x01_primary_math_sub_01_en.png';
import SampleImagePrimaryMathSub01Ja from '../image/sample/primary/math/sub/sample_x01_primary_math_sub_01_ja.png';
import SampleImagePrimaryMathSub02En from '../image/sample/primary/math/sub/sample_x01_primary_math_sub_02_en.png';
import SampleImagePrimaryMathSub02Ja from '../image/sample/primary/math/sub/sample_x01_primary_math_sub_02_ja.png';
import SampleImagePrimaryMathSub03En from '../image/sample/primary/math/sub/sample_x01_primary_math_sub_03_en.png';
import SampleImagePrimaryMathSub03Ja from '../image/sample/primary/math/sub/sample_x01_primary_math_sub_03_ja.png';

import SampleImagePrimaryMathMulti01En from '../image/sample/primary/math/multi/sample_x01_primary_math_multi_01_en.png';
import SampleImagePrimaryMathMulti01Ja from '../image/sample/primary/math/multi/sample_x01_primary_math_multi_01_ja.png';
import SampleImagePrimaryMathMulti02En from '../image/sample/primary/math/multi/sample_x01_primary_math_multi_02_en.png';
import SampleImagePrimaryMathMulti02Ja from '../image/sample/primary/math/multi/sample_x01_primary_math_multi_02_ja.png';
import SampleImagePrimaryMathMulti03En from '../image/sample/primary/math/multi/sample_x01_primary_math_multi_03_en.png';
import SampleImagePrimaryMathMulti03Ja from '../image/sample/primary/math/multi/sample_x01_primary_math_multi_03_ja.png';

import SampleImagePrimaryMathDiv01En from '../image/sample/primary/math/div/sample_x01_primary_math_div_01_en.png';
import SampleImagePrimaryMathDiv01Ja from '../image/sample/primary/math/div/sample_x01_primary_math_div_01_ja.png';
import SampleImagePrimaryMathDiv02En from '../image/sample/primary/math/div/sample_x01_primary_math_div_02_en.png';
import SampleImagePrimaryMathDiv02Ja from '../image/sample/primary/math/div/sample_x01_primary_math_div_02_ja.png';
import SampleImagePrimaryMathDiv03En from '../image/sample/primary/math/div/sample_x01_primary_math_div_03_en.png';
import SampleImagePrimaryMathDiv03Ja from '../image/sample/primary/math/div/sample_x01_primary_math_div_03_ja.png';
import SampleImagePrimaryMathDiv04En from '../image/sample/primary/math/div/sample_x01_primary_math_div_04_en.png';
import SampleImagePrimaryMathDiv04Ja from '../image/sample/primary/math/div/sample_x01_primary_math_div_04_ja.png';
import SampleImagePrimaryMathDiv05En from '../image/sample/primary/math/div/sample_x01_primary_math_div_05_en.png';
import SampleImagePrimaryMathDiv05Ja from '../image/sample/primary/math/div/sample_x01_primary_math_div_05_ja.png';
import SampleImagePrimaryMathDiv06En from '../image/sample/primary/math/div/sample_x01_primary_math_div_06_en.png';
import SampleImagePrimaryMathDiv06Ja from '../image/sample/primary/math/div/sample_x01_primary_math_div_06_ja.png';

// #endregion fields

// css
import "../layout/GlobalDesign.css";

//---------------------------------------------------------------------
// const
//
const defaultWidth = "100%";
const defaultHeight = "128px";

//-----------------------------------------------------------------------
// styled component (header)
//
const StyledHeaderContent = styled("div")(({ theme }) => ({
  // color
  backgroundColor: theme.palette.info.light,
  // size
  width: defaultWidth,
  height: defaultHeight,
  borderBottomLeftRadius: "8px",
  borderBottomRightRadius: "8px",
  // font / text
  display: "flex",
  alignItems: "center",
}));

const StyledHeaderTitle = styled("h4")(({ theme }) => ({
  // color
  color: theme.palette.primary.contrastText,
  // size
  margin: 0,
  paddingLeft: "1em",
  paddingRight: "1em",
  // font / text
  fontSize: "medium",
}));

//-----------------------------------------------------------------------
// styled component (description)
//
const StyledDescriptionRoot = styled("div")(({ theme }) => ({
  // size
  paddingLeft: 0,
  paddingRight: 0,
  paddingTop: "1.5em",
  paddingBottom: 0,
}));

const StyledDescriptionContent = styled("div")(({ theme }) => ({
  // color
  backgroundColor: theme.palette.secondary.light,
  color: theme.palette.primary.contrastText,
  // size
  paddingLeft: "1.5em",
  paddingRight: "1.5em",
  paddingTop: "1.5em",
  paddingBottom: "1.5em",
  // font / text
  border: "dashed 2px" + theme.palette.secondary.dark,
  borderRadius: "8px",
}));

const StyledDescriptionSpan = styled("span")(({ theme }) => ({
  // color
  color: theme.palette.primary.contrastText,
  // size
  minHight: "50px",
  // font / text
  fontSize: "small",
  padding: ".4em",
}));

const StyledContentCharImageRoot = styled("div")(({ theme }) => ({
  // size
  paddingLeft: ".5em",
  paddingRight: ".5em",
  paddingTop: ".5em",
  paddingBottom: ".5em",
}));

const StyledContentWorksheetImageRoot = styled("div")(({ theme }) => ({
  // size
  paddingLeft: ".5em",
  paddingRight: ".5em",
  paddingTop: ".5em",
  paddingBottom: ".5em",
}));

const StyledContentCharImage = styled("img")(({ theme }) => ({
  // size
  height: "auto",
  width: "100%",
}));

const StyledContentWorksheetImage = styled("img")(({ theme }) => ({
  // size
  height: "auto",
  width: "100%",
  // size
  paddingLeft: 0,
  paddingRight: 0,
  paddingTop: 0,
  paddingBottom: 0,
}));

//-----------------------------------------------------------------------
// styled component (unit)
//
const StyledContentGrid = styled(Grid)(({ theme }) => ({
  // size
  minHeight: "360px",
}));

const StyledPagination = styled(Pagination)(({ theme }) => ({
  padding: "1em",
}));

const StyledPaginationItem = styled(PaginationItem)(({ theme }) => ({
  // color
  color: theme.palette.primary.contrastText,
  // font / text
  fontSize: "small",
}));

//-----------------------------------------------------------------------
// styled component (modal)
//
const StyledModalBox = styled(Box)(({ theme }) => ({
  // color
  backgroundColor: theme.palette.primary.light,
  // position
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: 200,
  width: 400,
}));

const StyledModalBoxSmall = styled(Box)(({ theme }) => ({
  // color
  backgroundColor: theme.palette.primary.light,
  // position
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: 200,
  width: 200,
}));

const StyledModalSpan = styled("span")(({ theme }) => ({
  textAlign: "center",
  alignContent: "center",
}));

//-----------------------------------------------------------------------
// styled component (modal)
//
const StyledP = styled("p")(({ theme }) => ({
  // color
  color: theme.palette.primary.contrastText,
  // font / text
  fontSize: "small",
  padding: ".4em",
}));

const StyledSpacer = styled("div")(({ theme }) => ({
  // backgroung
  // backgroundColor: "#FFFFFF",
}));

//-----------------------------------------------------------------------
// function component
//
const Unit: React.FC = () => {

  //---------------------------------------------------------------------
  // context
  //
  const params = useParams();
  const {isEnglish, isAuthenticated} = useAuth();
  const [width, height] = useWindowSize();
  const [isWide, setIsWide] = React.useState<boolean>(false);
  const [pager, setPager] = React.useState<number>(0);
  const [open, setOpen] = React.useState(false);

  //---------------------------------------------------------------------
  // const
  //
  const pageKeyRoot = "/" + params.primary + "/" + params.secondary + "/" + params.tertiary
  const pageKey     = "/" + params.primary + "/" + params.secondary + "/" + params.tertiary + "/" + params.unit
  const pageUnit    = "/" + params.unit

  //---------------------------------------------------------------------
  // const method
  //
  const getContentMenuPrimary = (tempArray:IcontentMenuPrimary[]): IcontentMenuPrimary => {
    let pageKeyItems: string[] = pageKey.split('/');
    if(pageKeyItems.length > 1 && pageKeyItems[0] === ""){
      let tempContentMenuPrimaryArrayFiltered = (tempArray.length > 0) ? 
      tempArray.filter(item => item.content_menu_primary_key === pageKeyItems[1]) : [];
      return((tempContentMenuPrimaryArrayFiltered.length > 0) ? tempContentMenuPrimaryArrayFiltered[0] : initContentMenuPrimary);
    }else{
      return(initContentMenuPrimary);
    }
  }

  const getContentItemUnitInfo = (): IcontentItemUnitInfo => {
    let tempArray: IcontentItemUnitInfoRoot[] = configUnitInfo;
    let returnData: IcontentItemUnitInfo = initContentItemUnitInfo;
    if(tempArray.length > 0){
      let tempRootIndex = tempArray.findIndex(item => item.content_item_unit_root_path == pageKeyRoot);
      if(tempRootIndex >= 0){
        let tempIndex = tempArray[tempRootIndex].content_item_unit_root_data.findIndex(item => item.content_item_unit_path == pageKey);
        returnData = tempArray[tempRootIndex].content_item_unit_root_data[tempIndex];
      }
    }
    return returnData;
  }

  const getContentItemUnitData = (tempInfo:IcontentItemUnitInfo): IcontentItemUnitDataItem[] => {
    let returnArray: IcontentItemUnitDataItem[] = [];
    if(isEnglish){
      returnArray = tempInfo.content_item_unit_data_cr_en.content_item_unit_data;
    }else{
      returnArray = tempInfo.content_item_unit_data_cr_ja.content_item_unit_data;
    }
    return returnArray;
  }

  const getContentItemUnitDataPerPage = (tempArray:IcontentItemUnitDataItem[]): IcontentItemUnitDataItem[][] => {
    let pagedArray = new Array(Math.ceil(tempArray.length / 12));
    for (let index = 0; index < pagedArray.length; index++) {
      pagedArray[index] = tempArray.slice(index * 12, (index + 1) * 12);
    }
    return pagedArray;
  }

  const getContentItemUnitDataPerPageSmall = (tempArray:IcontentItemUnitDataItem[]): IcontentItemUnitDataItem[][] => {
    let pagedArray = new Array(Math.ceil(tempArray.length / 6));
    for (let index = 0; index < pagedArray.length; index++) {
      pagedArray[index] = tempArray.slice(index * 6, (index + 1) * 6);
    }
    return pagedArray;
  }

  //---------------------------------------------------------------------
  // const handler
  //
  //const handleOpen = () => setOpen(true);
  const handleOpen = () => setOpen(false);

  const handleClose = () => setOpen(false);

  //---------------------------------------------------------------------
  // conft config
  //
  const contentMenuPrimaryArray: IcontentMenuPrimary[] = configPrimary;
  const contentMenuPrimary: IcontentMenuPrimary = getContentMenuPrimary(contentMenuPrimaryArray);

  const contentItemUnitInfo: IcontentItemUnitInfo = getContentItemUnitInfo();
  const contentItemUnitData: IcontentItemUnitDataItem[] = getContentItemUnitData(contentItemUnitInfo);
  const contentItemUnitDataPerPage: IcontentItemUnitDataItem[][] = getContentItemUnitDataPerPage(contentItemUnitData);
  const contentItemUnitDataPerPageSmall: IcontentItemUnitDataItem[][] = getContentItemUnitDataPerPageSmall(contentItemUnitData);

  const localBreadcrumbs: Ibreadcrumb[] = [
    {
      key: "home",
      path: "/",
      text: isEnglish ? "HOME" : "ホーム",
    },
    {
      key: contentMenuPrimary.content_menu_primary_key,
      path: contentMenuPrimary.content_menu_primary_path,
      text: isEnglish ? contentMenuPrimary.content_menu_primary_text_en : contentMenuPrimary.content_menu_primary_text_ja,
    },
  ]

  const localBreadcrumbLastItem: Ibreadcrumb = {
    key: contentItemUnitInfo.content_item_unit_key,
    path: contentItemUnitInfo.content_item_unit_path,
    text: isEnglish ? contentItemUnitInfo.content_item_unit_text_en : contentItemUnitInfo.content_item_unit_text_ja,
  }

  //-----------------------------------------------------------------------
  // context
  //
  if(width > 1080){
    return (
      <MainContent
        mainContentBreadcrumbs={localBreadcrumbs}
        mainContentBreadcrumbLastItem={localBreadcrumbLastItem}
        contentMenuPrimaryArray={contentMenuPrimaryArray}
      >
        <ThemeProvider theme={globalTheme}>

          <Grid container>
            <Grid item xs={12}>
              <StyledHeaderContent>
                <StyledHeaderTitle>
                  {isEnglish ? contentItemUnitInfo.content_item_unit_text_en : contentItemUnitInfo.content_item_unit_text_ja}
                </StyledHeaderTitle>
              </StyledHeaderContent>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12}>
              <StyledDescriptionRoot>
                <StyledDescriptionContent>
                  <Grid container>
                    <Grid item xs={1}><StyledSpacer/></Grid>
                    <Grid item xs={5}>
                      <StyledContentCharImageRoot>
                        <div className="balloon1">
                          <StyledDescriptionSpan>
                            {isEnglish ? contentItemUnitInfo.content_item_unit_description_en : contentItemUnitInfo.content_item_unit_description_ja}
                          </StyledDescriptionSpan>
                        </div>
                        {contentMenuPrimary.content_menu_primary_key === "early"   && <StyledContentCharImage alt={"content_char_img"} src={ContentImageEarly}/>}
                        {contentMenuPrimary.content_menu_primary_key === "primary" && <StyledContentCharImage alt={"content_char_img"} src={ContentImagePrimary}/>}
                        {contentMenuPrimary.content_menu_primary_key === "digital" && <StyledContentCharImage alt={"content_char_img"} src={ContentImageDigital}/>}
                      </StyledContentCharImageRoot>
                    </Grid>
                    <Grid item xs={5}>
                      <StyledContentWorksheetImageRoot>
                        {(isEnglish && pageKey === "/early/number/practice/01" )        && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImageEarlyNumberPracticet01En}/>}
                        {(isEnglish && pageKey === "/early/maze/practice/01" )          && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImageEarlyMazePracticet01En}/>}
                        {(isEnglish && pageKey === "/primary/math/add/01" )             && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImagePrimaryMathAdd01En}/>}
                        {(isEnglish && pageKey === "/primary/math/add/02" )             && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImagePrimaryMathAdd02En}/>}
                        {(isEnglish && pageKey === "/primary/math/add/03" )             && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImagePrimaryMathAdd03En}/>}
                        {(isEnglish && pageKey === "/primary/math/sub/01" )             && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImagePrimaryMathSub01En}/>}
                        {(isEnglish && pageKey === "/primary/math/sub/02" )             && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImagePrimaryMathSub02En}/>}
                        {(isEnglish && pageKey === "/primary/math/sub/03" )             && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImagePrimaryMathSub03En}/>}
                        {(isEnglish && pageKey === "/primary/math/multi/01" )           && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImagePrimaryMathMulti01En}/>}
                        {(isEnglish && pageKey === "/primary/math/multi/02" )           && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImagePrimaryMathMulti02En}/>}
                        {(isEnglish && pageKey === "/primary/math/multi/03" )           && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImagePrimaryMathMulti03En}/>}
                        {(isEnglish && pageKey === "/primary/math/div/01" )             && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImagePrimaryMathDiv01En}/>}
                        {(isEnglish && pageKey === "/primary/math/div/02" )             && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImagePrimaryMathDiv02En}/>}
                        {(isEnglish && pageKey === "/primary/math/div/03" )             && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImagePrimaryMathDiv03En}/>}
                        {(isEnglish && pageKey === "/primary/math/div/04" )             && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImagePrimaryMathDiv04En}/>}
                        {(isEnglish && pageKey === "/primary/math/div/05" )             && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImagePrimaryMathDiv05En}/>}
                        {(isEnglish && pageKey === "/primary/math/div/06" )             && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImagePrimaryMathDiv06En}/>}
                        {(isEnglish && pageKey === "/primary/english/alphabet/01" )     && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImagePrimaryEnglishAlphabet01En}/>}
                        {(isEnglish && pageKey === "/primary/english/alphabet/02" )     && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImagePrimaryEnglishAlphabet02En}/>}
                        {(isEnglish && pageKey === "/primary/japanese/romeji/01" )      && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImagePrimaryJapaneseRomeji01En}/>}
                        {(isEnglish && pageKey === "/digital/programming/scratch/01" )  && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImageDigitalProgrammingScratch01En}/>}

                        {(!isEnglish && pageKey === "/early/number/practice/01" )       && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImageEarlyNumberPracticet01Ja}/>}
                        {(!isEnglish && pageKey === "/early/maze/practice/01" )         && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImageEarlyMazePracticet01Ja}/>}
                        {(!isEnglish && pageKey === "/primary/math/add/01" )            && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImagePrimaryMathAdd01Ja}/>}
                        {(!isEnglish && pageKey === "/primary/math/add/02" )            && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImagePrimaryMathAdd02Ja}/>}
                        {(!isEnglish && pageKey === "/primary/math/add/03" )            && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImagePrimaryMathAdd03Ja}/>}
                        {(!isEnglish && pageKey === "/primary/math/sub/01" )            && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImagePrimaryMathSub01Ja}/>}
                        {(!isEnglish && pageKey === "/primary/math/sub/02" )            && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImagePrimaryMathSub02Ja}/>}
                        {(!isEnglish && pageKey === "/primary/math/sub/03" )            && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImagePrimaryMathSub03Ja}/>}
                        {(!isEnglish && pageKey === "/primary/math/multi/01" )          && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImagePrimaryMathMulti01Ja}/>}
                        {(!isEnglish && pageKey === "/primary/math/multi/02" )          && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImagePrimaryMathMulti02Ja}/>}
                        {(!isEnglish && pageKey === "/primary/math/multi/03" )          && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImagePrimaryMathMulti03Ja}/>}
                        {(!isEnglish && pageKey === "/primary/math/div/01" )            && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImagePrimaryMathDiv01Ja}/>}
                        {(!isEnglish && pageKey === "/primary/math/div/02" )            && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImagePrimaryMathDiv02Ja}/>}
                        {(!isEnglish && pageKey === "/primary/math/div/03" )            && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImagePrimaryMathDiv03Ja}/>}
                        {(!isEnglish && pageKey === "/primary/math/div/04" )            && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImagePrimaryMathDiv04Ja}/>}
                        {(!isEnglish && pageKey === "/primary/math/div/05" )            && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImagePrimaryMathDiv05Ja}/>}
                        {(!isEnglish && pageKey === "/primary/math/div/06" )            && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImagePrimaryMathDiv06Ja}/>}
                        {(!isEnglish && pageKey === "/primary/english/alphabet/01" )    && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImagePrimaryEnglishAlphabet01Ja}/>}
                        {(!isEnglish && pageKey === "/primary/english/alphabet/02" )    && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImagePrimaryEnglishAlphabet02Ja}/>}
                        {(!isEnglish && pageKey === "/primary/japanese/romeji/01" )     && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImagePrimaryJapaneseRomeji01Ja}/>}
                        {(!isEnglish && pageKey === "/digital/programming/scratch/01" ) && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImageDigitalProgrammingScratch01Ja}/>}
                      </StyledContentWorksheetImageRoot>
                    </Grid>
                    <Grid item xs={1}><StyledSpacer/></Grid>
                  </Grid>
                </StyledDescriptionContent>
              </StyledDescriptionRoot>
            </Grid>
          </Grid>

          <StyledContentGrid container>
            {contentItemUnitDataPerPage[pager].map((contentItemUnit, index) => {
              return(
                <Grid item xs={4}>
                  <UnitCard
                    unitCardIsInglish={isEnglish}
                    unitCardIndex={pager * 12 + index + 1}
                    unitCardModalOpen={handleOpen}
                    unitCardPageKeyRoot={pageKeyRoot}
                    unitCardPageUnit={pageUnit}
                    unitCardQuestionDownloadkey={contentItemUnit.content_item_unit_data_question_download_key}
                    unitCardAnswerDownloadkey={contentItemUnit.content_item_unit_data_answer_download_key}
                    unitCardQuestionUrl={contentItemUnit.content_item_unit_data_question_url}
                    unitCardAnswerUrl={contentItemUnit.content_item_unit_data_answer_url}
                  />
                </Grid>
              );
            })}
          </StyledContentGrid>

          <Grid container>
            <Grid item xs={12}>
              <StyledPagination
                size={"small"}
                count={contentItemUnitDataPerPage.length}
                onChange={(_: any, page: number) => { setPager(page - 1) }}
                renderItem={(item) => ( <StyledPaginationItem {...item}/>)}
              />
            </Grid>
          </Grid>

          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <StyledModalBox>
              <StyledModalSpan>
                Text in a modal
              </StyledModalSpan>
            </StyledModalBox>
          </Modal>

        </ThemeProvider>
      </MainContent>
    );
  }else{
    return (
      <MainContentSubSmall
        mainContentBreadcrumbs={localBreadcrumbs}
        mainContentBreadcrumbLastItem={localBreadcrumbLastItem}
        contentMenuPrimaryArray={contentMenuPrimaryArray}
      >
        <ThemeProvider theme={globalTheme}>

          <Grid container>
            <Grid item xs={12}>
              <StyledHeaderContent>
                <StyledHeaderTitle>
                  {isEnglish ? contentItemUnitInfo.content_item_unit_text_en : contentItemUnitInfo.content_item_unit_text_ja}
                </StyledHeaderTitle>
              </StyledHeaderContent>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12}>
              <StyledDescriptionRoot>
                <StyledDescriptionContent>
                  <Grid container>
                    <Grid item xs={6}>
                      <StyledContentCharImageRoot>
                        <div className="balloon1">
                          <StyledDescriptionSpan>
                            {isEnglish ? contentItemUnitInfo.content_item_unit_description_en : contentItemUnitInfo.content_item_unit_description_ja}
                          </StyledDescriptionSpan>
                        </div>
                        {contentMenuPrimary.content_menu_primary_key === "early"   && <StyledContentCharImage alt={"content_char_img"} src={ContentImageEarly}/>}
                        {contentMenuPrimary.content_menu_primary_key === "primary" && <StyledContentCharImage alt={"content_char_img"} src={ContentImagePrimary}/>}
                        {contentMenuPrimary.content_menu_primary_key === "digital" && <StyledContentCharImage alt={"content_char_img"} src={ContentImageDigital}/>}
                      </StyledContentCharImageRoot>
                    </Grid>
                    <Grid item xs={6}>
                      <StyledContentWorksheetImageRoot>
                        {(isEnglish && pageKey === "/early/number/practice/01" )        && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImageEarlyNumberPracticet01En}/>}
                        {(isEnglish && pageKey === "/early/maze/practice/01" )          && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImageEarlyMazePracticet01En}/>}
                        {(isEnglish && pageKey === "/primary/math/add/01" )             && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImagePrimaryMathAdd01En}/>}
                        {(isEnglish && pageKey === "/primary/math/add/02" )             && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImagePrimaryMathAdd02En}/>}
                        {(isEnglish && pageKey === "/primary/math/add/03" )             && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImagePrimaryMathAdd03En}/>}
                        {(isEnglish && pageKey === "/primary/math/sub/01" )             && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImagePrimaryMathSub01En}/>}
                        {(isEnglish && pageKey === "/primary/math/sub/02" )             && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImagePrimaryMathSub02En}/>}
                        {(isEnglish && pageKey === "/primary/math/sub/03" )             && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImagePrimaryMathSub03En}/>}
                        {(isEnglish && pageKey === "/primary/math/multi/01" )           && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImagePrimaryMathMulti01En}/>}
                        {(isEnglish && pageKey === "/primary/math/multi/02" )           && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImagePrimaryMathMulti02En}/>}
                        {(isEnglish && pageKey === "/primary/math/multi/03" )           && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImagePrimaryMathMulti03En}/>}
                        {(isEnglish && pageKey === "/primary/math/div/01" )             && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImagePrimaryMathDiv01En}/>}
                        {(isEnglish && pageKey === "/primary/math/div/02" )             && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImagePrimaryMathDiv02En}/>}
                        {(isEnglish && pageKey === "/primary/math/div/03" )             && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImagePrimaryMathDiv03En}/>}
                        {(isEnglish && pageKey === "/primary/math/div/04" )             && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImagePrimaryMathDiv04En}/>}
                        {(isEnglish && pageKey === "/primary/math/div/05" )             && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImagePrimaryMathDiv05En}/>}
                        {(isEnglish && pageKey === "/primary/math/div/06" )             && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImagePrimaryMathDiv06En}/>}
                        {(isEnglish && pageKey === "/primary/english/alphabet/01" )     && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImagePrimaryEnglishAlphabet01En}/>}
                        {(isEnglish && pageKey === "/primary/english/alphabet/02" )     && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImagePrimaryEnglishAlphabet02En}/>}
                        {(isEnglish && pageKey === "/primary/japanese/romeji/01" )      && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImagePrimaryJapaneseRomeji01En}/>}
                        {(isEnglish && pageKey === "/digital/programming/scratch/01" )  && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImageDigitalProgrammingScratch01En}/>}

                        {(!isEnglish && pageKey === "/early/number/practice/01" )       && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImageEarlyNumberPracticet01Ja}/>}
                        {(!isEnglish && pageKey === "/early/maze/practice/01" )         && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImageEarlyMazePracticet01Ja}/>}
                        {(!isEnglish && pageKey === "/primary/math/add/01" )            && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImagePrimaryMathAdd01Ja}/>}
                        {(!isEnglish && pageKey === "/primary/math/add/02" )            && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImagePrimaryMathAdd02Ja}/>}
                        {(!isEnglish && pageKey === "/primary/math/add/03" )            && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImagePrimaryMathAdd03Ja}/>}
                        {(!isEnglish && pageKey === "/primary/math/sub/01" )            && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImagePrimaryMathSub01Ja}/>}
                        {(!isEnglish && pageKey === "/primary/math/sub/02" )            && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImagePrimaryMathSub02Ja}/>}
                        {(!isEnglish && pageKey === "/primary/math/sub/03" )            && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImagePrimaryMathSub03Ja}/>}
                        {(!isEnglish && pageKey === "/primary/math/multi/01" )          && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImagePrimaryMathMulti01Ja}/>}
                        {(!isEnglish && pageKey === "/primary/math/multi/02" )          && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImagePrimaryMathMulti02Ja}/>}
                        {(!isEnglish && pageKey === "/primary/math/multi/03" )          && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImagePrimaryMathMulti03Ja}/>}
                        {(!isEnglish && pageKey === "/primary/math/div/01" )            && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImagePrimaryMathDiv01Ja}/>}
                        {(!isEnglish && pageKey === "/primary/math/div/02" )            && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImagePrimaryMathDiv02Ja}/>}
                        {(!isEnglish && pageKey === "/primary/math/div/03" )            && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImagePrimaryMathDiv03Ja}/>}
                        {(!isEnglish && pageKey === "/primary/math/div/04" )            && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImagePrimaryMathDiv04Ja}/>}
                        {(!isEnglish && pageKey === "/primary/math/div/05" )            && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImagePrimaryMathDiv05Ja}/>}
                        {(!isEnglish && pageKey === "/primary/math/div/06" )            && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImagePrimaryMathDiv06Ja}/>}
                        {(!isEnglish && pageKey === "/primary/english/alphabet/01" )    && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImagePrimaryEnglishAlphabet01Ja}/>}
                        {(!isEnglish && pageKey === "/primary/english/alphabet/02" )    && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImagePrimaryEnglishAlphabet02Ja}/>}
                        {(!isEnglish && pageKey === "/primary/japanese/romeji/01" )     && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImagePrimaryJapaneseRomeji01Ja}/>}
                        {(!isEnglish && pageKey === "/digital/programming/scratch/01" ) && <StyledContentWorksheetImage alt={"content_worksheet_img"} src={SampleImageDigitalProgrammingScratch01Ja}/>}
                      </StyledContentWorksheetImageRoot>
                    </Grid>
                  </Grid>
                </StyledDescriptionContent>
              </StyledDescriptionRoot>
            </Grid>
          </Grid>

          <StyledContentGrid container>
            {contentItemUnitDataPerPageSmall[pager].map((contentItemUnit, index) => {
              return(
                <Grid item xs={12}>
                  <UnitCard
                    unitCardIsInglish={isEnglish}
                    unitCardIndex={pager * 6 + index + 1}
                    unitCardModalOpen={handleOpen}
                    unitCardPageKeyRoot={pageKeyRoot}
                    unitCardPageUnit={pageUnit}
                    unitCardQuestionDownloadkey={contentItemUnit.content_item_unit_data_question_download_key}
                    unitCardAnswerDownloadkey={contentItemUnit.content_item_unit_data_answer_download_key}
                    unitCardQuestionUrl={contentItemUnit.content_item_unit_data_question_url}
                    unitCardAnswerUrl={contentItemUnit.content_item_unit_data_answer_url}
                  />
                </Grid>
              );
            })}
          </StyledContentGrid>

          <Grid container>
            <Grid item xs={12}>
              <StyledPagination
                size={"small"}
                count={contentItemUnitDataPerPageSmall.length}
                onChange={(_: any, page: number) => { setPager(page - 1) }}
                renderItem={(item) => ( <StyledPaginationItem {...item}/>)}
              />
            </Grid>
          </Grid>

          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <StyledModalBoxSmall>
              <StyledModalSpan>
                Text in a modal
              </StyledModalSpan>
            </StyledModalBoxSmall>
          </Modal>

        </ThemeProvider>
      </MainContentSubSmall>
    );
  }

}

export default Unit;