import * as React from "react";
import { Grid, List, Breadcrumbs, Link } from '@mui/material';
import { ThemeProvider, styled } from "@mui/material/styles";

// local module
import { useAuth } from "../context/GlobalContext";
import { Ibreadcrumb, IcontentMenuPrimary } from  "../type/ContentType";
import { globalTheme } from "./GlobalStyle";
import GlobalHeaderSmall from "./GlobalHeaderSmall";
import MainHeaderSmall from "./MainHeaderSmall";

// image
import SideMenuSubHeaderTextImageEarlyEn from '../image/header_text_early_en.png';
import SideMenuSubHeaderTextImageEarlyJa from '../image/header_text_early_ja.png';
import SideMenuSubHeaderTextImagePrimaryEn from '../image/header_text_primary_en.png';
import SideMenuSubHeaderTextImagePrimaryJa from '../image/header_text_primary_ja.png';
import SideMenuSubHeaderTextImageDigitalEn from '../image/header_text_digital_en.png';
import SideMenuSubHeaderTextImageDigitalJa from '../image/header_text_digital_ja.png';

import SideMenuSubHeaderImageEarly from '../image/char_early_01.png';
import SideMenuSubHeaderImagePrimary from '../image/char_primary_01.png';
import SideMenuSubHeaderImageDigital from '../image/char_digital_01.png';

//-----------------------------------------------------------------------
// const
//
const breadcrumbsFontSize = "xx-small";
const breadcrumbsPadding  = "0.4m";

const breadcrumbsHeight = "24px";

//-----------------------------------------------------------------------
// styled component (area)
//
const StyledContent = styled("div")(({ theme }) => ({
  // backgroung
  backgroundColor: theme.palette.primary.light,
  // content
  display: "flex",
}));

const StyledBreadcrumbsDiv = styled("div")(({ theme }) => ({
  // backgroung
  backgroundColor: theme.palette.secondary.main,
  // size
  height: breadcrumbsHeight,
  // content
  display: "flex",
  alignItems: "center",
}));

const StyledSideMenuListRoot = styled("div")(({ theme }) => ({
  // size
  paddingLeft: 0,
  paddingRight: 0,
  paddingTop: "1.5em",
  paddingBottom: 0,
}));

const StyledSideMenuList = styled(List)(({ theme }) => ({
}));

const StyledSideMenuSubHeaderDiv = styled("div")(({ theme }) => ({
  // backgroung
  backgroundImage: "radial-gradient(#f74545 0.5px, #e3b3ce 0.5px)",
  backgroundSize: "10px 10px",
  opacity: 1,
  // size
  height: "75px",
  borderTopLeftRadius: "8px",
  borderTopRightRadius: "8px",
  // content
  display: "flex",
  alignItems: "center",
  fontSize: "small",
}));

const StyledSpacer = styled("div")(({ theme }) => ({
  // size
  width: '10px'
}));

const StyledBreadcrumbsSpacer = styled("div")(({ theme }) => ({
  // size
  width: '10px'
}));

//-----------------------------------------------------------------------
// styled component (breadcrums content)
//
const StyledBreadcrumbs = styled(Breadcrumbs)(({ theme }) => ({
  // text
  color: theme.palette.primary.contrastText,
  fontSize: breadcrumbsFontSize,
  padding: breadcrumbsPadding,
}));

const StyledBreadcrumbsLink = styled(Link)(({ theme }) => ({
  // text
  color: theme.palette.primary.contrastText,
  fontSize: breadcrumbsFontSize,
  padding: breadcrumbsPadding,
}));

const StyledBreadcrumbsSpan = styled("span")(({ theme }) => ({
  // text
  color: theme.palette.primary.contrastText,
  fontSize: breadcrumbsFontSize,
  padding: breadcrumbsPadding,
}));

//-----------------------------------------------------------------------
// styled component (sidemenu content)
//
const StyledSideMenuSubHeaderTextImage = styled("img")(({ theme }) => ({
  // size
  maxWidth: "256px",
}));

const StyledSideMenuSubHeaderImage = styled("img")(({ theme }) => ({
  // size
  height: "64px",
  width: "64px",
}));

const StyledSideMenuSubHeaderLink = styled("a")(({ theme }) => ({
  // position
  display: "block",
  position: "absolute",
  top: "0",
  left: "0",
  width: "100%",
  height: "100%",
}));

const StyledSideMenuSubHeaderSpan = styled("span")(({ theme }) => ({
  // position
  position: "absolute",
  top:"50%",
  left:"0",
  transform:"translate(0%, -50%)",
  // size
  width:"100%",
  // color
  color: theme.palette.primary.contrastText,
  // font / text
  fontSize: "small",
  padding: ".4em",
}));

const StyledSideMenuListItemDiv = styled("div")(({ theme }) => ({
  // backgroung
  backgroundColor: theme.palette.secondary.light,
  // size
  height: "50px",
  // content
  position: "relative",
  fontSize: "small",
}));

const StyledSideMenuListItemLink = styled("a")(({ theme }) => ({
  // position
  display: "block",
  position: "absolute",
  top: "0",
  left: "0",
  width: "100%",
  height: "100%",
}));

const StyledSideMenuSpan = styled("span")(({ theme }) => ({
  // position
  display: "flex",
  alignItems: "center",
  height: "100%",
  // color
  color: theme.palette.primary.contrastText,
  // font / text
  fontSize: "small",
  paddingLeft: ".4em",
}));

//-----------------------------------------------------------------------
// props
//
interface Props {
  mainContentBreadcrumbs: Ibreadcrumb[];
  mainContentBreadcrumbLastItem: Ibreadcrumb;
  contentMenuPrimaryArray: IcontentMenuPrimary[];
  children: React.ReactNode;
}

//-----------------------------------------------------------------------
// function component
//
const MainContentSmall: React.FC<Props> = ({mainContentBreadcrumbs, mainContentBreadcrumbLastItem, contentMenuPrimaryArray, children}) =>  {

  //---------------------------------------------------------------------
  // const
  //
  const {isEnglish} = useAuth();

  //---------------------------------------------------------------------
  // main
  //
  return (
    <GlobalHeaderSmall>
      <MainHeaderSmall>
        <ThemeProvider theme={globalTheme}>
          <StyledContent>
            <Grid container>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12}>
                    <StyledBreadcrumbsDiv>
                      <StyledBreadcrumbsSpacer/>
                      <StyledBreadcrumbs separator=">" aria-label="breadcrumb">
                        {mainContentBreadcrumbs.map((breadcrumb) => {
                          return(
                            <StyledBreadcrumbsLink underline="hover" key={breadcrumb.key} href={breadcrumb.path}>
                              {breadcrumb.text}
                            </StyledBreadcrumbsLink>
                          )
                        })}
                        <StyledBreadcrumbsSpan>
                          {mainContentBreadcrumbLastItem.text}
                        </StyledBreadcrumbsSpan>
                      </StyledBreadcrumbs>
                    </StyledBreadcrumbsDiv>
                    {children}
                  </Grid>
                  <Grid item xs={12}>
                    <StyledSideMenuListRoot>
                      {contentMenuPrimaryArray.map((sideMenu) => {
                        return(
                          <StyledSideMenuList
                            aria-labelledby={sideMenu.content_menu_primary_key}
                            subheader={
                              <StyledSideMenuSubHeaderDiv>
                                {(isEnglish && sideMenu.content_menu_primary_key === "early"   ) && <StyledSideMenuSubHeaderTextImage alt={"header_text_img"} src={SideMenuSubHeaderTextImageEarlyEn}/>}
                                {(isEnglish && sideMenu.content_menu_primary_key === "primary" ) && <StyledSideMenuSubHeaderTextImage alt={"header_text_img"} src={SideMenuSubHeaderTextImagePrimaryEn}/>}
                                {(isEnglish && sideMenu.content_menu_primary_key === "digital" ) && <StyledSideMenuSubHeaderTextImage alt={"header_text_img"} src={SideMenuSubHeaderTextImageDigitalEn}/>}
                                {(!isEnglish && sideMenu.content_menu_primary_key === "early"   ) && <StyledSideMenuSubHeaderTextImage alt={"header_text_img"} src={SideMenuSubHeaderTextImageEarlyJa}/>}
                                {(!isEnglish && sideMenu.content_menu_primary_key === "primary" ) && <StyledSideMenuSubHeaderTextImage alt={"header_text_img"} src={SideMenuSubHeaderTextImagePrimaryJa}/>}
                                {(!isEnglish && sideMenu.content_menu_primary_key === "digital" ) && <StyledSideMenuSubHeaderTextImage alt={"header_text_img"} src={SideMenuSubHeaderTextImageDigitalJa}/>}
                                {sideMenu.content_menu_primary_key == "early" && <StyledSideMenuSubHeaderImage alt={sideMenu.content_menu_primary_text_en + "_img"} src={SideMenuSubHeaderImageEarly}/>}
                                {sideMenu.content_menu_primary_key == "primary" && <StyledSideMenuSubHeaderImage alt={sideMenu.content_menu_primary_text_en + "_img"} src={SideMenuSubHeaderImagePrimary}/>}
                                {sideMenu.content_menu_primary_key == "digital" && <StyledSideMenuSubHeaderImage alt={sideMenu.content_menu_primary_text_en + "_img"} src={SideMenuSubHeaderImageDigital}/>}
                                <StyledSideMenuSubHeaderLink id={sideMenu.content_menu_primary_key} href={sideMenu.content_menu_primary_path}/>
                              </StyledSideMenuSubHeaderDiv>
                            }
                          >
                            {sideMenu.content_menu_secondary_array.map((sideMenuItem) => {
                              return(
                                <StyledSideMenuListItemDiv>
                                  <StyledSideMenuSpan><StyledSpacer/>{" ▶ "}{isEnglish ? sideMenuItem.content_menu_secondary_text_en : sideMenuItem.content_menu_secondary_text_ja}</StyledSideMenuSpan>
                                  <StyledSideMenuListItemLink id={sideMenuItem.content_menu_secondary_key} href={sideMenuItem.content_menu_secondary_path}/>
                                </StyledSideMenuListItemDiv>
                              );
                            })}
                          </StyledSideMenuList>
                        )
                      })}
                    </StyledSideMenuListRoot>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </StyledContent>
        </ThemeProvider>
      </MainHeaderSmall>
    </GlobalHeaderSmall>
  );
}

export default MainContentSmall;
