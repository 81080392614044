import React from "react";
import { Grid, Link } from '@mui/material';
import { ThemeProvider, styled } from "@mui/material/styles"

// local module
import { useAuth } from "../context/GlobalContext";
import { useWindowSize } from "../context/WindowSizeContext";
import { Ibreadcrumb, IcontentMenuPrimary } from  "../type/ContentType";
import { globalTheme } from "../layout/GlobalStyle";
import { configPrimary } from "../config/primary";
import MainContent from "../layout/MainContent";
import MainContentSmall from "../layout/MainContentSmall";

// image
import ImageHowToUsePcEn01 from '../image/howtouse_pc_en_01.png';
import ImageHowToUsePcEn02 from '../image/howtouse_pc_en_02.png';
import ImageHowToUsePcEn03 from '../image/howtouse_pc_en_03.png';
import ImageHowToUsePcEn04 from '../image/howtouse_pc_en_04.png';

import ImageHowToUseEn01 from '../image/howtouse_en_01.png';
import ImageHowToUseEn02 from '../image/howtouse_en_02.png';
import ImageHowToUseEn03 from '../image/howtouse_en_03.png';
import ImageHowToUseEn04 from '../image/howtouse_en_04.png';
import ImageHowToUseEn05 from '../image/howtouse_en_05.png';
import ImageHowToUseEn06 from '../image/howtouse_en_06.png';

import ImageHowToUsePcJa01 from '../image/howtouse_pc_ja_01.png';
import ImageHowToUsePcJa02 from '../image/howtouse_pc_ja_02.png';
import ImageHowToUsePcJa03 from '../image/howtouse_pc_ja_03.png';
import ImageHowToUsePcJa04 from '../image/howtouse_pc_ja_04.png';

import ImageHowToUseJa01 from '../image/howtouse_ja_01.png';
import ImageHowToUseJa02 from '../image/howtouse_ja_02.png';
import ImageHowToUseJa03 from '../image/howtouse_ja_03.png';
import ImageHowToUseJa04 from '../image/howtouse_ja_04.png';
import ImageHowToUseJa05 from '../image/howtouse_ja_05.png';
import ImageHowToUseJa06 from '../image/howtouse_ja_06.png';

//---------------------------------------------------------------------
// const
//
const defaultWidth = "100%";
const defaultHeight = "128px";

//-----------------------------------------------------------------------
// const message system
//
const labelAboutEn           = "How to use this site";
const labelAboutJa           = "このサイトについて";

class globalMessageSystemClass {
  labelAbout:               string;
  constructor(
    labelAbout:               string
  ) {
    this.labelAbout               = labelAbout;
  }
}

const globalMessageSystem = (language: boolean): globalMessageSystemClass => {
  if(language){
    return new globalMessageSystemClass(
      labelAboutEn
    );
  }else{
    return new globalMessageSystemClass(
      labelAboutJa
    );
  }
}

//-----------------------------------------------------------------------
// styled component (header)
//
const StyledHeaderContent = styled("div")(({ theme }) => ({
  // color
  backgroundColor: theme.palette.secondary.light,
  // size
  width: defaultWidth,
  height: defaultHeight,
  borderBottomLeftRadius: "8px",
  borderBottomRightRadius: "8px",
  // font / text
  display: "flex",
  alignItems: "center",
}));

const StyledContentRoot = styled("div")(({ theme }) => ({
  // size
  paddingLeft: 0,
  paddingRight: 0,
  paddingTop: "1.5em",
  paddingBottom: 0,
}));

const StyledContentTitleRoot = styled("div")(({ theme }) => ({
  // size
  paddingLeft: 0,
  paddingRight: 0,
  paddingTop: 0,
  paddingBottom: 0,
}));

const StyledContentSmallTitleRoot = styled("div")(({ theme }) => ({
  // size
  paddingLeft: 0,
  paddingRight: 0,
  paddingTop: 0,
  paddingBottom: 0,
}));

const StyledContentTitle = styled("h4")(({ theme }) => ({
  // color
  color: theme.palette.primary.contrastText,
  // size
  margin: 0,
  paddingBottom: ".5em",
  // font / text
  fontSize: "small",
}));

const StyledContentText = styled("div")(({ theme }) => ({
  // color
  backgroundColor: theme.palette.secondary.light,
  color: theme.palette.primary.contrastText,
  // size
  paddingLeft: "0.5em",
  paddingRight: "0.5em",
  paddingTop: "0.2em",
  paddingBottom: "0.2em",
  // font / text
  border: "dashed 2px" + theme.palette.secondary.dark,
  borderRadius: "8px",
}));

const StyledContentGridRoot = styled("div")(({ theme }) => ({
  // size
  paddingLeft: "1.5em",
  paddingRight: "1.5em",
  paddingTop: "1.5em",
  paddingBottom: "1.5em",
}));

const StyledContentGridTitle = styled("div")(({ theme }) => ({
  // size
  paddingLeft: 0,
  paddingRight: 0,
  paddingTop: 0,
  paddingBottom: 0,
}));

const StyledContentGridText = styled("div")(({ theme }) => ({
  // size
  paddingLeft: 0,
  paddingRight: 0,
  paddingTop: "1em",
  paddingBottom: 0,
}));

const StyledContentSmallGridRoot = styled("div")(({ theme }) => ({
  // size
  paddingLeft: ".5em",
  paddingRight: ".5em",
  paddingTop: "1.5em",
  paddingBottom: "1.5em",
}));

const StyledHeaderTitle = styled("h4")(({ theme }) => ({
  // color
  color: theme.palette.primary.contrastText,
  // size
  margin: 0,
  paddingLeft: "1em",
  paddingRight: "1em",
  // font / text
  fontSize: "medium",
}));

//-----------------------------------------------------------------------
// styled component (content)
//
const StyledHeaderP = styled("p")(({ theme }) => ({
  // color
  color: theme.palette.primary.contrastText,
  // size
  margin: 0,
  paddingLeft: "1em",
  paddingRight: "1em",
  // font / text
  fontSize: "medium",
}));

const StyledHeaderSpan = styled("span")(({ theme }) => ({
  fontWeight: "bold",
}));

const StyledSpan = styled("span")(({ theme }) => ({
  // color
  color: theme.palette.primary.contrastText,
  // font / text
  fontSize: "small",
  padding: ".4em",
}));

const StyledSpanNews = styled("span")(({ theme }) => ({
  // color
  color: theme.palette.primary.contrastText,
  // font / text
  display: "block",
  fontSize: "small",
  padding: ".4em",
}));

const StyledContentImageRoot = styled("div")(({ theme }) => ({
  // size
  paddingLeft: "1.5em",
  paddingRight: "1.5em",
  paddingTop: "1.5em",
  paddingBottom: "1.5em",
}));

const StyledContentTextRoot = styled("div")(({ theme }) => ({
  // size
  paddingLeft: "1.5em",
  paddingRight: "1.5em",
  paddingTop: "1.5em",
  paddingBottom: "1.5em",
}));

const StyledContentSmallImageRoot = styled("div")(({ theme }) => ({
  // size
  paddingLeft: ".5em",
  paddingRight: ".5em",
  paddingTop: "1.5em",
  paddingBottom: "1.5em",
}));

const StyledContentSmallTextRoot = styled("div")(({ theme }) => ({
  // size
  paddingLeft: "1em",
  paddingRight: ".5em",
  paddingTop: "1.5em",
  paddingBottom: "1.5em",
}));

const StyledContentImage = styled("img")(({ theme }) => ({
  // color
  backgroundColor: "white",
  // size
  height: "auto",
  width: "100%",
  paddingLeft: ".5em",
  paddingRight: ".5em",
  paddingTop: ".5em",
  paddingBottom: ".5em",
}));

const StyledContentP = styled("p")(({ theme }) => ({
  // color
  color: theme.palette.primary.contrastText,
  // font / text
  fontSize: "small",
  padding: ".4em",
}));

//-----------------------------------------------------------------------
// function component
//
const About: React.FC = () => {

  //---------------------------------------------------------------------
  // context
  //
  const {isEnglish} = useAuth();
  const [width, height] = useWindowSize();

  //---------------------------------------------------------------------
  // const
  //
  const contentMenuPrimaryArray: IcontentMenuPrimary[] = configPrimary;

  const localBreadcrumbs: Ibreadcrumb[] = [
    {
      key: "home",
      path: "/",
      text: isEnglish ? "HOME" : "ホーム",
    },
  ]

  const localBreadcrumbLastItem: Ibreadcrumb = {
    key: "about",
    path: "/topics/about",
    text: globalMessageSystem(isEnglish).labelAbout,
  }

  //-----------------------------------------------------------------------
  // context
  //
  if(width > 1080){
    return (
      <MainContent
        mainContentBreadcrumbs={localBreadcrumbs}
        mainContentBreadcrumbLastItem={localBreadcrumbLastItem}
        contentMenuPrimaryArray={contentMenuPrimaryArray}
      >
        <ThemeProvider theme={globalTheme}>
          <Grid container>
            <Grid item xs={12}>
              <StyledHeaderContent>
                <StyledHeaderTitle>
                  {globalMessageSystem(isEnglish).labelAbout}
                </StyledHeaderTitle>
              </StyledHeaderContent>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12}>
              <StyledContentRoot>
                <StyledContentText>
                  {isEnglish && 
                    <StyledContentGridRoot>
                      <StyledContentGridTitle>
                        <Grid container>
                          <Grid item xs={12}>
                            <StyledContentTitleRoot>
                              <StyledContentTitle>{"◆ Our mission"}</StyledContentTitle>
                            </StyledContentTitleRoot>
                          </Grid>
                          <Grid item xs={12}>
                            <StyledContentTitleRoot>
                              <StyledContentP>{"Thank you for visitting the web site."}<br/>{"Our mission is to support lerning at your home."}<br/>{"All worksheet data are download free."}</StyledContentP>
                            </StyledContentTitleRoot>
                          </Grid>
                        </Grid>
                      </StyledContentGridTitle>
                      <StyledContentGridText>
                        <Grid container>
                          <Grid item xs={12}>
                            <StyledContentTitleRoot>
                              <StyledContentTitle>{"◆ How to use the web site."}</StyledContentTitle>
                            </StyledContentTitleRoot>
                          </Grid>
                          <Grid item xs={6}><StyledContentImageRoot><StyledContentImage alt={"content_en_01_img"} src={ImageHowToUsePcEn01}/></StyledContentImageRoot></Grid>
                          <Grid item xs={6}><StyledContentTextRoot><StyledContentP>{"Select worksheet category at the home page"}<br/>{"We selected MATH here."}</StyledContentP></StyledContentTextRoot></Grid>
                          <Grid item xs={6}><StyledContentImageRoot><StyledContentImage alt={"content_en_02_img"} src={ImageHowToUsePcEn02}/></StyledContentImageRoot></Grid>
                          <Grid item xs={6}><StyledContentTextRoot><StyledContentP>{"Then select question type at the next page."}<br/>{"We selected Addition of single digits (Beginner) here."}</StyledContentP></StyledContentTextRoot></Grid>
                          <Grid item xs={6}><StyledContentImageRoot><StyledContentImage alt={"content_en_03_img"} src={ImageHowToUsePcEn03}/></StyledContentImageRoot></Grid>
                          <Grid item xs={6}><StyledContentTextRoot><StyledContentP>{"There are list of questions and answers. Then select one you want."}</StyledContentP></StyledContentTextRoot></Grid>
                          <Grid item xs={6}><StyledContentImageRoot><StyledContentImage alt={"content_en_04_img"} src={ImageHowToUsePcEn04}/></StyledContentImageRoot></Grid>
                          <Grid item xs={6}><StyledContentTextRoot><StyledContentP>{"When a worksheet is shown, select print icon at top right of the window and print worksheet data."}</StyledContentP></StyledContentTextRoot></Grid>
                        </Grid>
                      </StyledContentGridText>
                      <StyledContentGridText>
                        <Grid container>
                          <Grid item xs={12}>
                            <StyledContentTitleRoot>
                              <StyledContentP>{"Please help you with our contents!"}</StyledContentP>
                            </StyledContentTitleRoot>
                          </Grid>
                        </Grid>
                      </StyledContentGridText>
                    </StyledContentGridRoot>
                  }
                  {!isEnglish && 
                    <StyledContentGridRoot>
                      <StyledContentGridTitle>
                        <Grid container>
                          <Grid item xs={12}>
                            <StyledContentTitleRoot>
                              <StyledContentTitle>{"◆ このサイトの目的"}</StyledContentTitle>
                            </StyledContentTitleRoot>
                          </Grid>
                          <Grid item xs={12}>
                            <StyledContentTitleRoot>
                              <StyledContentP>{"ご訪問ありがとうございます。"}<br/>{"このサイトは、おうち学習のサポートを目的として運営されています。"}<br/>{"このサイトに掲載しているプリントデータは全て無料でダウンロードしてお使いいただけます。"}</StyledContentP>
                            </StyledContentTitleRoot>
                          </Grid>
                        </Grid>
                      </StyledContentGridTitle>
                      <StyledContentGridText>
                        <Grid container>
                          <Grid item xs={12}>
                            <StyledContentTitleRoot>
                              <StyledContentTitle>{"◆ このサイトの使い方"}</StyledContentTitle>
                            </StyledContentTitleRoot>
                          </Grid>
                          <Grid item xs={6}><StyledContentImageRoot><StyledContentImage alt={"content_ja_01_img"} src={ImageHowToUsePcJa01}/></StyledContentImageRoot></Grid>
                          <Grid item xs={6}><StyledContentTextRoot><StyledContentP>{"ホーム画面でプリントの種類を選択します。"}<br/>{"ここでは例として「算数」を選択しています。"}</StyledContentP></StyledContentTextRoot></Grid>
                          <Grid item xs={6}><StyledContentImageRoot><StyledContentImage alt={"content_ja_02_img"} src={ImageHowToUsePcJa02}/></StyledContentImageRoot></Grid>
                          <Grid item xs={6}><StyledContentTextRoot><StyledContentP>{"次に問題の種類を選択します。"}<br/>{"ここでは例として「ひとけたの足し算（初級）」を選択しています。"}</StyledContentP></StyledContentTextRoot></Grid>
                          <Grid item xs={6}><StyledContentImageRoot><StyledContentImage alt={"content_ja_03_img"} src={ImageHowToUsePcJa03}/></StyledContentImageRoot></Grid>
                          <Grid item xs={6}><StyledContentTextRoot><StyledContentP>{"「問題」と「答え」の一覧が表示されます。必要なものを選択します。"}</StyledContentP></StyledContentTextRoot></Grid>
                          <Grid item xs={6}><StyledContentImageRoot><StyledContentImage alt={"content_ja_04_img"} src={ImageHowToUsePcJa04}/></StyledContentImageRoot></Grid>
                          <Grid item xs={6}><StyledContentTextRoot><StyledContentP>{"プリントが表示されたら、右上のプリンタアイコンを選択して印刷します。"}<br/>{"※画像はEdgeの例です。Chromeなど他のブラウザの場合は表示が異なります。"}</StyledContentP></StyledContentTextRoot></Grid>
                        </Grid>
                      </StyledContentGridText>
                      <StyledContentGridText>
                        <Grid container>
                          <Grid item xs={12}>
                            <StyledContentTitleRoot>
                              <StyledContentP>{"どうぞ皆様の学習にお役立てください！"}</StyledContentP>
                            </StyledContentTitleRoot>
                          </Grid>
                        </Grid>
                      </StyledContentGridText>
                    </StyledContentGridRoot>
                  }
                </StyledContentText>
              </StyledContentRoot>
            </Grid>
          </Grid>

        </ThemeProvider>
      </MainContent>
    );
  }else{
    return (
      <MainContentSmall
        mainContentBreadcrumbs={localBreadcrumbs}
        mainContentBreadcrumbLastItem={localBreadcrumbLastItem}
        contentMenuPrimaryArray={contentMenuPrimaryArray}
      >
        <ThemeProvider theme={globalTheme}>
          <Grid container>
            <Grid item xs={12}>
              <StyledHeaderContent>
                <StyledHeaderTitle>
                  {globalMessageSystem(isEnglish).labelAbout}
                </StyledHeaderTitle>
              </StyledHeaderContent>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12}>
              <StyledContentRoot>
                <StyledContentText>
                  {isEnglish && 
                    <StyledContentSmallGridRoot>
                      <StyledContentGridTitle>
                        <Grid container>
                          <Grid item xs={12}>
                            <StyledContentSmallTitleRoot>
                              <StyledContentTitle>{"◆ Our mission"}</StyledContentTitle>
                            </StyledContentSmallTitleRoot>
                          </Grid>
                          <Grid item xs={12}>
                            <StyledContentSmallTitleRoot>
                            <StyledContentP>{"Thank you for visitting the web site."}<br/>{"Our mission is to support lerning at your home."}<br/>{"All worksheet data are download free."}</StyledContentP>
                            </StyledContentSmallTitleRoot>
                          </Grid>
                        </Grid>
                      </StyledContentGridTitle>
                      <StyledContentGridText>
                        <Grid container>
                          <Grid item xs={12}>
                            <StyledContentSmallTitleRoot>
                              <StyledContentTitle>{"◆ How to use the web site."}</StyledContentTitle>
                            </StyledContentSmallTitleRoot>
                          </Grid>
                          <Grid item xs={6}><StyledContentSmallImageRoot><StyledContentImage alt={"content_en_01_img"} src={ImageHowToUseEn01}/></StyledContentSmallImageRoot></Grid>
                          <Grid item xs={6}><StyledContentSmallTextRoot><StyledContentP>{"Select worksheet category at the home page"}<br/>{"We selected MATH here."}</StyledContentP></StyledContentSmallTextRoot></Grid>
                          <Grid item xs={6}><StyledContentSmallImageRoot><StyledContentImage alt={"content_en_02_img"} src={ImageHowToUseEn02}/></StyledContentSmallImageRoot></Grid>
                          <Grid item xs={6}><StyledContentSmallTextRoot><StyledContentP>{"Then select question type at the next page."}<br/>{"We selected Addition of single digits (Beginner) here."}</StyledContentP></StyledContentSmallTextRoot></Grid>
                          <Grid item xs={6}><StyledContentSmallImageRoot><StyledContentImage alt={"content_en_03_img"} src={ImageHowToUseEn03}/></StyledContentSmallImageRoot></Grid>
                          <Grid item xs={6}><StyledContentSmallTextRoot><StyledContentP>{"There are list of questions and answers. Then select one you want."}</StyledContentP></StyledContentSmallTextRoot></Grid>
                          <Grid item xs={6}><StyledContentSmallImageRoot><StyledContentImage alt={"content_en_04_img"} src={ImageHowToUseEn04}/></StyledContentSmallImageRoot></Grid>
                          <Grid item xs={6}><StyledContentSmallTextRoot><StyledContentP>{"When a worksheet is shown, select the arrow icon."}</StyledContentP></StyledContentSmallTextRoot></Grid>
                          <Grid item xs={6}><StyledContentSmallImageRoot><StyledContentImage alt={"content_en_05_img"} src={ImageHowToUseEn05}/></StyledContentSmallImageRoot></Grid>
                          <Grid item xs={6}><StyledContentSmallTextRoot><StyledContentP>{"Scroll page to the bottom and select print button."}</StyledContentP></StyledContentSmallTextRoot></Grid>
                          <Grid item xs={6}><StyledContentSmallImageRoot><StyledContentImage alt={"content_en_06_img"} src={ImageHowToUseEn06}/></StyledContentSmallImageRoot></Grid>
                          <Grid item xs={6}><StyledContentSmallTextRoot><StyledContentP>{"Select print button at right top of the page and print worksheet data."}</StyledContentP></StyledContentSmallTextRoot></Grid>
                        </Grid>
                      </StyledContentGridText>
                      <StyledContentGridText>
                        <Grid container>
                          <Grid item xs={12}>
                            <StyledContentSmallTitleRoot>
                              <StyledContentP>{"Please help you with our contents!"}</StyledContentP>
                            </StyledContentSmallTitleRoot>
                          </Grid>
                        </Grid>
                      </StyledContentGridText>
                    </StyledContentSmallGridRoot>
                  }
                  {!isEnglish && 
                    <StyledContentSmallGridRoot>
                      <StyledContentGridTitle>
                        <Grid container>
                          <Grid item xs={12}>
                            <StyledContentSmallTitleRoot>
                              <StyledContentTitle>{"◆ このサイトの目的"}</StyledContentTitle>
                            </StyledContentSmallTitleRoot>
                          </Grid>
                          <Grid item xs={12}>
                            <StyledContentSmallTitleRoot>
                              <StyledContentP>{"ご訪問ありがとうございます。"}<br/>{"このサイトは、おうち学習のサポートを目的として運営されています。"}<br/>{"このサイトに掲載しているプリントデータは全て無料でダウンロードしてお使いいただけます。"}</StyledContentP>
                            </StyledContentSmallTitleRoot>
                          </Grid>
                        </Grid>
                      </StyledContentGridTitle>
                      <StyledContentGridText>
                        <Grid container>
                          <Grid item xs={12}>
                            <StyledContentSmallTitleRoot>
                              <StyledContentTitle>{"◆ このサイトの使い方"}</StyledContentTitle>
                            </StyledContentSmallTitleRoot>
                          </Grid>
                          <Grid item xs={6}><StyledContentSmallImageRoot><StyledContentImage alt={"content_ja_01_img"} src={ImageHowToUseJa01}/></StyledContentSmallImageRoot></Grid>
                          <Grid item xs={6}><StyledContentSmallTextRoot><StyledContentP>{"ホーム画面でプリントの種類をタップします。"}<br/>{"ここでは例として「算数」をタップしています。"}</StyledContentP></StyledContentSmallTextRoot></Grid>
                          <Grid item xs={6}><StyledContentSmallImageRoot><StyledContentImage alt={"content_ja_02_img"} src={ImageHowToUseJa02}/></StyledContentSmallImageRoot></Grid>
                          <Grid item xs={6}><StyledContentSmallTextRoot><StyledContentP>{"次に問題の種類をタップします。"}<br/>{"ここでは例として「ひとけたの足し算（初級）」をタップしています。"}</StyledContentP></StyledContentSmallTextRoot></Grid>
                          <Grid item xs={6}><StyledContentSmallImageRoot><StyledContentImage alt={"content_ja_03_img"} src={ImageHowToUseJa03}/></StyledContentSmallImageRoot></Grid>
                          <Grid item xs={6}><StyledContentSmallTextRoot><StyledContentP>{"「問題」と「答え」の一覧が表示されます。必要なものを選んでタップします。"}</StyledContentP></StyledContentSmallTextRoot></Grid>
                          <Grid item xs={6}><StyledContentSmallImageRoot><StyledContentImage alt={"content_ja_04_img"} src={ImageHowToUseJa04}/></StyledContentSmallImageRoot></Grid>
                          <Grid item xs={6}><StyledContentSmallTextRoot><StyledContentP>{"プリントが表示されたら、矢印のボタンをタップします。"}<br/>{"※以下iPhoneの例です。Androidの場合は表示が異なります。"}</StyledContentP></StyledContentSmallTextRoot></Grid>
                          <Grid item xs={6}><StyledContentSmallImageRoot><StyledContentImage alt={"content_ja_05_img"} src={ImageHowToUseJa05}/></StyledContentSmallImageRoot></Grid>
                          <Grid item xs={6}><StyledContentSmallTextRoot><StyledContentP>{"画面を下にスクロールして「プリント」ボタンをタップします。"}</StyledContentP></StyledContentSmallTextRoot></Grid>
                          <Grid item xs={6}><StyledContentSmallImageRoot><StyledContentImage alt={"content_ja_06_img"} src={ImageHowToUseJa06}/></StyledContentSmallImageRoot></Grid>
                          <Grid item xs={6}><StyledContentSmallTextRoot><StyledContentP>{"画面右上の「プリント」ボタンをタップして印刷します。"}</StyledContentP></StyledContentSmallTextRoot></Grid>
                        </Grid>
                      </StyledContentGridText>
                      <StyledContentGridText>
                        <Grid container>
                          <Grid item xs={12}>
                            <StyledContentSmallTitleRoot>
                              <StyledContentP>{"どうぞ皆様の学習にお役立てください！"}</StyledContentP>
                            </StyledContentSmallTitleRoot>
                          </Grid>
                        </Grid>
                      </StyledContentGridText>
                    </StyledContentSmallGridRoot>
                  }
                </StyledContentText>
              </StyledContentRoot>
            </Grid>
          </Grid>

        </ThemeProvider>
      </MainContentSmall>
    );
  }
}

export default About;