import * as React from "react";
import { Grid, Link } from '@mui/material';
import { ThemeProvider, styled } from "@mui/material/styles";

// local module
import { useAuth } from "../context/GlobalContext";
import { globalTheme } from "./GlobalStyle";

// image
import MainHeaderTitleEnImage from '../image/main_header_title_en.png';
import MainHeaderTitleJaImage from '../image/main_header_title_ja.png';
import MainHeaderCharImage from '../image/main_header_char.png';

import MainHeaderQrcodeImage from '../image/qrcode.png';

//-----------------------------------------------------------------------
// const
//
const contentHeaderFontSize = "small";
const contentHeaderPadding  = ".4em";
const contentHeaderHeight = "128px";

//-----------------------------------------------------------------------
// styled component (area)
//
const StyledContentHeader = styled("header")(({ theme }) => ({
  // backgroung
  backgroundColor: theme.palette.primary.light,
  // size
  height: contentHeaderHeight,
}));

const StyledContent = styled("div")(({ theme }) => ({
  // backgroung
  backgroundColor: theme.palette.primary.light,
  // size
  height: contentHeaderHeight,
  // content
  display: "flex",
  alignItems: "center",
}));

const StyledContentLink = styled(Link)(({ theme }) => ({
}));

const StyledContentQrcodeWrap = styled("div")(({ theme }) => ({
  // size
  height: contentHeaderHeight,
  paddingRight: "2em",
  paddingBottom: "2em",
  // content
  display: "flex",
  alignItems: "end"
}));

const StyledSpacer = styled("div")(({ theme }) => ({
  // content
  flexGrow: 1
}));

//-----------------------------------------------------------------------
// styled component (content)
//
const StyledMainHeaderTitleImage = styled("img")(({ theme }) => ({
  // size
  height: contentHeaderHeight,
  width: "512px",
}));

const StyledMainHeaderCharImage = styled("img")(({ theme }) => ({
  // size
  height: contentHeaderHeight,
  width: "256px",
}));

const StyledMainHeaderQrcodeImage = styled("img")(({ theme }) => ({
  // size
  height: "48px",
  width: "48px",
}));

const StyledSpan = styled("span")(({ theme }) => ({
  // text
  color: theme.palette.primary.contrastText,
  fontSize: contentHeaderFontSize,
  padding: contentHeaderPadding,
}));

const StyledP = styled("p")(({ theme }) => ({
  // text
  color: theme.palette.primary.contrastText,
  fontSize: contentHeaderFontSize,
  padding: contentHeaderPadding,
}));

//-----------------------------------------------------------------------
// props
//
interface Props {
  children: React.ReactNode;
}

//-----------------------------------------------------------------------
// function component
//
const MainHeader: React.FC<Props> = ({children}) =>  {

  //---------------------------------------------------------------------
  // const
  //
  const {isEnglish} = useAuth();

  //---------------------------------------------------------------------
  // main
  //
  return (
    <ThemeProvider theme={globalTheme}>
      <StyledContentHeader>
        <Grid container>
          <Grid item xs={2}><StyledSpacer/></Grid>
          <Grid item xs={8}>
            <StyledContentLink href={"/"}>
              <StyledContent>
                {isEnglish  && <StyledMainHeaderTitleImage alt={"worksheet lab"} src={MainHeaderTitleEnImage}/>}
                {!isEnglish && <StyledMainHeaderTitleImage alt={"worksheet lab"} src={MainHeaderTitleJaImage}/>}
                <StyledMainHeaderCharImage alt={"character"} src={MainHeaderCharImage}/>
                <StyledSpacer/>
                <StyledContentQrcodeWrap>
                  <StyledSpan>{"for mobile ▶ "}</StyledSpan>
                  <StyledMainHeaderQrcodeImage alt={"qrcode"} src={MainHeaderQrcodeImage}/>
                </StyledContentQrcodeWrap>
              </StyledContent>
            </StyledContentLink>
          </Grid>
          <Grid item xs={2}><StyledSpacer/></Grid>
        </Grid>
      </StyledContentHeader>
      {children}
    </ThemeProvider>
  );
}

export default MainHeader;
