import React from 'react';
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";

import Index from "./pages/Index";
import Topics from "./pages/Topics";
import About from "./pages/About";
import Contact from "./pages/Contact";
import News from "./pages/News";
import SubIndex from "./pages/SubIndex";
import UnitIndex from "./pages/UnitIndex";

const App: React.FC = () => {

  return (
    <Router>
      <Routes>
        <Route index element={<Index/>}></Route>
        <Route path="/home" element={<Index/>}></Route>
        <Route path="/:primary" element={<SubIndex/>}></Route>
        <Route path="/topics/about" element={<About/>}></Route>
        <Route path="/topics/contact" element={<Contact/>}></Route>
        <Route path="/topics/:content" element={<Topics/>}></Route>
        <Route path="/news/:content" element={<News/>}></Route>
        <Route path="/:primary/:secondary/:tertiary/:unit" element={<UnitIndex/>}></Route>
      </Routes>
    </Router>
  );
}

export default App;
