import React from "react";
import { Grid, Link } from '@mui/material';
import { ThemeProvider, styled } from "@mui/material/styles"

// local module
import { useAuth } from "../context/GlobalContext";
import { useWindowSize } from "../context/WindowSizeContext";
import { Ibreadcrumb, IcontentMenuPrimary } from  "../type/ContentType";
import { globalTheme } from "../layout/GlobalStyle";
import { configPrimary } from "../config/primary";
import MainContent from "../layout/MainContent";
import MainContentSmall from "../layout/MainContentSmall";

// image
import ImageTwitterLogo from '../image/2021 Twitter logo - blue.png';
import ImageInstagramLogo from '../image/Instagram_Glyph_Gradient copy.png';

//---------------------------------------------------------------------
// const
//
const defaultWidth = "100%";
const defaultHeight = "128px";

//-----------------------------------------------------------------------
// const message system
//
const labelContactEn           = "Contact us";
const labelContactJa           = "お問い合わせ";

const labelCommingSoonEn       = "Comming soon.";
const labelCommingSoonJa       = "工事中…";

class globalMessageSystemClass {
  labelContact:               string;
  labelCommingSoon:           string;
  constructor(
    labelContact:               string,
    labelCommingSoon:           string,
  ) {
    this.labelContact               = labelContact;
    this.labelCommingSoon           = labelCommingSoon;
  }
}

const globalMessageSystem = (language: boolean): globalMessageSystemClass => {
  if(language){
    return new globalMessageSystemClass(
      labelContactEn,
      labelCommingSoonEn
    );
  }else{
    return new globalMessageSystemClass(
      labelContactJa,
      labelCommingSoonJa
    );
  }
}

//-----------------------------------------------------------------------
// styled component (header)
//
const StyledHeaderContent = styled("div")(({ theme }) => ({
  // color
  backgroundColor: theme.palette.secondary.light,
  // size
  width: defaultWidth,
  height: defaultHeight,
  borderBottomLeftRadius: "8px",
  borderBottomRightRadius: "8px",
  // font / text
  display: "flex",
  alignItems: "center",
}));

const StyledContentRoot = styled("div")(({ theme }) => ({
  // size
  paddingLeft: 0,
  paddingRight: 0,
  paddingTop: "1.5em",
  paddingBottom: 0,
}));

const StyledContentTitleRoot = styled("div")(({ theme }) => ({
  // size
  paddingLeft: 0,
  paddingRight: 0,
  paddingTop: 0,
  paddingBottom: 0,
}));

const StyledContentSmallTitleRoot = styled("div")(({ theme }) => ({
  // size
  paddingLeft: 0,
  paddingRight: 0,
  paddingTop: 0,
  paddingBottom: 0,
}));

const StyledContentTitle = styled("h4")(({ theme }) => ({
  // color
  color: theme.palette.primary.contrastText,
  // size
  margin: 0,
  paddingBottom: ".5em",
  // font / text
  fontSize: "medium",
}));

const StyledContentText = styled("div")(({ theme }) => ({
  // color
  backgroundColor: theme.palette.secondary.light,
  color: theme.palette.primary.contrastText,
  // size
  paddingLeft: "0.5em",
  paddingRight: "0.5em",
  paddingTop: "0.2em",
  paddingBottom: "0.2em",
  // font / text
  border: "dashed 2px" + theme.palette.secondary.dark,
  borderRadius: "8px",
}));

const StyledHeaderTitle = styled("h4")(({ theme }) => ({
  // color
  color: theme.palette.primary.contrastText,
  // size
  margin: 0,
  paddingLeft: "1em",
  paddingRight: "1em",
  // font / text
  fontSize: "medium",
}));

const StyledContentGridRoot = styled("div")(({ theme }) => ({
  // size
  paddingLeft: "1.5em",
  paddingRight: "1.5em",
  paddingTop: "1.5em",
  paddingBottom: "1.5em",
}));

const StyledContentSmallGridRoot = styled("div")(({ theme }) => ({
  // size
  paddingLeft: ".5em",
  paddingRight: ".5em",
  paddingTop: "1.5em",
  paddingBottom: "1.5em",
}));

const StyledContentGridTitle = styled("div")(({ theme }) => ({
  // size
  paddingLeft: 0,
  paddingRight: 0,
  paddingTop: 0,
  paddingBottom: 0,
}));

const StyledContentGridText = styled("div")(({ theme }) => ({
  // size
  paddingLeft: 0,
  paddingRight: 0,
  paddingTop: ".5em",
  paddingBottom: 0,
}));

//-----------------------------------------------------------------------
// styled component (content)
//
const StyledContentImageRoot = styled("div")(({ theme }) => ({
  // size
  width: "25px",
  height: "25px",
  paddingLeft: "1.5em",
  paddingRight: "1.5em",
  paddingTop: "1.5em",
  paddingBottom: "1.5em",
}));

const StyledContentTextRoot = styled("div")(({ theme }) => ({
  // size
  paddingLeft: "1.5em",
  paddingRight: "1.5em",
  paddingTop: ".5em",
  paddingBottom: ".5em",
}));

const StyledContentSmallTextRoot = styled("div")(({ theme }) => ({
  // size
  paddingLeft: ".5em",
  paddingRight: ".5em",
  paddingTop: ".5em",
  paddingBottom: ".5em",
}));

const StyledHeaderP = styled("p")(({ theme }) => ({
  // color
  color: theme.palette.primary.contrastText,
  // size
  margin: 0,
  paddingLeft: "1em",
  paddingRight: "1em",
  // font / text
  fontSize: "medium",
}));

const StyledP = styled("p")(({ theme }) => ({
  // color
  color: theme.palette.primary.contrastText,
  // font / text
  fontSize: "small",
  padding: ".4em",
}));

const StyledHeaderSpan = styled("span")(({ theme }) => ({
  fontWeight: "bold",
}));

const StyledSpan = styled("span")(({ theme }) => ({
  // color
  color: theme.palette.primary.contrastText,
  // font / text
  fontSize: "small",
  padding: ".4em",
}));

const StyledSpanNews = styled("span")(({ theme }) => ({
  // color
  color: theme.palette.primary.contrastText,
  // font / text
  display: "block",
  fontSize: "small",
  padding: ".4em",
}));

const StyledLink = styled(Link)(({ theme }) => ({
}));

const StyledContentImage = styled("img")(({ theme }) => ({
  // color
  backgroundColor: "white",
  // size
  height: "auto",
  width: "100%",
  paddingLeft: ".5em",
  paddingRight: ".5em",
  paddingTop: ".5em",
  paddingBottom: ".5em",
}));

const StyledContentP = styled("p")(({ theme }) => ({
  // color
  color: theme.palette.primary.contrastText,
  // font / text
  fontSize: "small",
  padding: ".4em",
}));

const StyledContentSpan = styled("span")(({ theme }) => ({
  // color
  color: theme.palette.primary.contrastText,
  // font / text
  fontSize: "small",
  padding: ".4em",
}));

//-----------------------------------------------------------------------
// function component
//
const Contact: React.FC = () => {

  //---------------------------------------------------------------------
  // context
  //
  const {isEnglish} = useAuth();
  const [width, height] = useWindowSize();

  //---------------------------------------------------------------------
  // const
  //
  const contentMenuPrimaryArray: IcontentMenuPrimary[] = configPrimary;

  const localBreadcrumbs: Ibreadcrumb[] = [
    {
      key: "home",
      path: "/",
      text: isEnglish ? "HOME" : "ホーム",
    },
  ]

  const localBreadcrumbLastItem: Ibreadcrumb = {
    key: "contact",
    path: "/topics/contact",
    text: globalMessageSystem(isEnglish).labelContact,
  }

  //-----------------------------------------------------------------------
  // context
  //
  if(width > 1080){
    return (
      <MainContent
        mainContentBreadcrumbs={localBreadcrumbs}
        mainContentBreadcrumbLastItem={localBreadcrumbLastItem}
        contentMenuPrimaryArray={contentMenuPrimaryArray}
      >
        <ThemeProvider theme={globalTheme}>
          <Grid container>
            <Grid item xs={12}>
              <StyledHeaderContent>
                <StyledHeaderTitle>
                  {globalMessageSystem(isEnglish).labelContact}
                </StyledHeaderTitle>
              </StyledHeaderContent>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12}>
              <StyledContentRoot>
                <StyledContentText>
                  {isEnglish && 
                    <StyledContentGridRoot>
                      <StyledContentGridTitle>
                        <Grid container>
                          <Grid item xs={12}>
                            <StyledContentTitleRoot>
                              <StyledContentP>
                                {"When you have any question about the web site or data we publish on the web site,"}<br/>
                                {"please send message by following SNS."}
                              </StyledContentP>
                            </StyledContentTitleRoot>
                          </Grid>
                        </Grid>
                      </StyledContentGridTitle>
                      <StyledContentGridText>
                        <Grid container>
                          <Grid item xs={12}>
                            <StyledContentTextRoot>
                              <StyledLink href={"https://www.instagram.com/nomurabbit2019/"} target={"_blank"}>
                                <StyledContentSpan>
                                  {"▶ Instagram : @nomurabbit"}
                                </StyledContentSpan>
                              </StyledLink>
                            </StyledContentTextRoot>
                          </Grid>
                        </Grid>
                      </StyledContentGridText>
                      <StyledContentGridText>
                        <Grid container>
                          <Grid item xs={12}>
                            <StyledContentTextRoot>
                              <StyledLink href={"https://twitter.com/nomurabbit/"} target={"_blank"}>
                                <StyledContentSpan>
                                  {"▶ Twitter : @nomurabbit"}
                                </StyledContentSpan>
                              </StyledLink>
                            </StyledContentTextRoot>
                          </Grid>
                        </Grid>
                      </StyledContentGridText>
                    </StyledContentGridRoot>
                  }
                  {!isEnglish && 
                    <StyledContentGridRoot>
                      <StyledContentGridTitle>
                        <Grid container>
                          <Grid item xs={12}>
                            <StyledContentTitleRoot>
                              <StyledContentP>
                                {"当サイトおよび当サイトで配布しているデータに関するお問い合わせは、"}<br/>
                                {"下記SNSのメッセージにてお送りください。"}
                              </StyledContentP>
                            </StyledContentTitleRoot>
                          </Grid>
                        </Grid>
                      </StyledContentGridTitle>
                      <StyledContentGridText>
                        <Grid container>
                          <Grid item xs={12}>
                            <StyledContentTextRoot>
                              <StyledLink href={"https://www.instagram.com/nomurabbit2019/"} target={"_blank"}>
                                <StyledContentSpan>
                                  {"▶ Instagram : @nomurabbit"}
                                </StyledContentSpan>
                              </StyledLink>
                            </StyledContentTextRoot>
                          </Grid>
                        </Grid>
                      </StyledContentGridText>
                      <StyledContentGridText>
                        <Grid container>
                          <Grid item xs={12}>
                            <StyledContentTextRoot>
                              <StyledLink href={"https://twitter.com/nomurabbit/"} target={"_blank"}>
                                <StyledContentSpan>
                                  {"▶ Twitter : @nomurabbit"}
                                </StyledContentSpan>
                              </StyledLink>
                            </StyledContentTextRoot>
                          </Grid>
                        </Grid>
                      </StyledContentGridText>
                    </StyledContentGridRoot>
                  }
                </StyledContentText>
              </StyledContentRoot>
            </Grid>
          </Grid>

        </ThemeProvider>
      </MainContent>
    );
  }else{
    return (
      <MainContentSmall
        mainContentBreadcrumbs={localBreadcrumbs}
        mainContentBreadcrumbLastItem={localBreadcrumbLastItem}
        contentMenuPrimaryArray={contentMenuPrimaryArray}
      >
        <ThemeProvider theme={globalTheme}>
          <Grid container>
            <Grid item xs={12}>
              <StyledHeaderContent>
                <StyledHeaderTitle>
                  {globalMessageSystem(isEnglish).labelContact}
                </StyledHeaderTitle>
              </StyledHeaderContent>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12}>
              <StyledContentRoot>
                <StyledContentText>
                  {isEnglish && 
                    <StyledContentSmallGridRoot>
                      <StyledContentGridTitle>
                        <Grid container>
                          <Grid item xs={12}>
                            <StyledContentSmallTitleRoot>
                              <StyledContentP>
                                {"When you have any question about the web site or data we publish on the web site,"}<br/>
                                {"please send message by following SNS."}
                              </StyledContentP>
                            </StyledContentSmallTitleRoot>
                          </Grid>
                        </Grid>
                      </StyledContentGridTitle>
                      <StyledContentGridText>
                        <Grid container>
                          <Grid item xs={12}>
                            <StyledContentSmallTextRoot>
                              <StyledLink href={"https://www.instagram.com/nomurabbit2019/"} target={"_blank"}>
                                <StyledContentSpan>
                                  {"▶ Instagram : @nomurabbit"}
                                </StyledContentSpan>
                              </StyledLink>
                            </StyledContentSmallTextRoot>
                          </Grid>
                        </Grid>
                      </StyledContentGridText>
                      <StyledContentGridText>
                        <Grid container>
                          <Grid item xs={12}>
                            <StyledContentSmallTextRoot>
                              <StyledLink href={"https://twitter.com/nomurabbit/"} target={"_blank"}>
                                <StyledContentSpan>
                                  {"▶ Twitter : @nomurabbit"}
                                </StyledContentSpan>
                              </StyledLink>
                            </StyledContentSmallTextRoot>
                          </Grid>
                        </Grid>
                      </StyledContentGridText>
                    </StyledContentSmallGridRoot>
                  }
                  {!isEnglish && 
                    <StyledContentSmallGridRoot>
                      <StyledContentGridTitle>
                        <Grid container>
                          <Grid item xs={12}>
                            <StyledContentSmallTitleRoot>
                              <StyledContentP>
                                {"当サイトおよび当サイトで配布しているデータに関するお問い合わせは、"}<br/>
                                {"下記SNSのメッセージにてお送りください。"}
                              </StyledContentP>
                            </StyledContentSmallTitleRoot>
                          </Grid>
                        </Grid>
                      </StyledContentGridTitle>
                      <StyledContentGridText>
                        <Grid container>
                          <Grid item xs={12}>
                            <StyledContentSmallTextRoot>
                              <StyledLink href={"https://www.instagram.com/nomurabbit2019/"} target={"_blank"}>
                                <StyledContentSpan>
                                  {"▶ Instagram : @nomurabbit"}
                                </StyledContentSpan>
                              </StyledLink>
                            </StyledContentSmallTextRoot>
                          </Grid>
                        </Grid>
                      </StyledContentGridText>
                      <StyledContentGridText>
                        <Grid container>
                          <Grid item xs={12}>
                            <StyledContentSmallTextRoot>
                              <StyledLink href={"https://twitter.com/nomurabbit/"} target={"_blank"}>
                                <StyledContentSpan>
                                  {"▶ Twitter : @nomurabbit"}
                                </StyledContentSpan>
                              </StyledLink>
                            </StyledContentSmallTextRoot>
                          </Grid>
                        </Grid>
                      </StyledContentGridText>
                    </StyledContentSmallGridRoot>
                  }
                </StyledContentText>
              </StyledContentRoot>
            </Grid>
          </Grid>

        </ThemeProvider>
      </MainContentSmall>
    );
  }
}

export default Contact;