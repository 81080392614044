import * as React from "react";
import { Grid, Link } from '@mui/material';
import { ThemeProvider, styled } from "@mui/material/styles";

// local module
import { useAuth } from "../context/GlobalContext";
import { globalTheme } from "./GlobalStyle";

// image
import MainHeaderTitleEnImage from '../image/main_header_title_en.png';
import MainHeaderTitleJaImage from '../image/main_header_title_ja.png';
import MainHeaderCharImage from '../image/main_header_char_small.png';

//-----------------------------------------------------------------------
// const
//
const contentHeaderFontSize = "small";
const contentHeaderPadding  = ".4em";
const contentHeaderHeight = "128px";
const contentHeaderHeightHalf = "64px";

//-----------------------------------------------------------------------
// styled component (area)
//
const StyledContentHeader = styled("header")(({ theme }) => ({
  // backgroung
  backgroundColor: theme.palette.primary.light,
  backgroundSize: "cover",
  // size
  height: contentHeaderHeight,
}));

const StyledContent = styled("div")(({ theme }) => ({
  // backgroung
  backgroundColor: theme.palette.primary.light,
  // size
  height: contentHeaderHeightHalf,
  // content
  display: "flex",
  alignItems: "center",
}));

const StyledContentLink = styled(Link)(({ theme }) => ({
}));

const StyledSpacer = styled("div")(({ theme }) => ({
  // content
  flexGrow: 1,
}));

//-----------------------------------------------------------------------
// styled component (content)
//
const StyledMainHeaderImage = styled("img")(({ theme }) => ({
  // size
  height: contentHeaderHeight,
  width: "256px",
}));

const StyledMainHeaderImageHalf = styled("img")(({ theme }) => ({
  // size
  height: contentHeaderHeightHalf,
  width: "256px",
}));

const StyledP = styled("p")(({ theme }) => ({
  // text
  color: theme.palette.primary.contrastText,
  fontSize: contentHeaderFontSize,
  padding: contentHeaderPadding,
}));

//-----------------------------------------------------------------------
// props
//
interface Props {
  children: React.ReactNode;
}

//-----------------------------------------------------------------------
// function component
//
const MainHeaderSmall: React.FC<Props> = ({children}) =>  {

  //---------------------------------------------------------------------
  // const
  //
  const {isEnglish} = useAuth();

  //---------------------------------------------------------------------
  // main
  //
  return (
    <ThemeProvider theme={globalTheme}>
      <StyledContentHeader>
        <StyledContentLink href={"/"}>
          <Grid container>
            <Grid item xs={12}>
              <StyledContent>
                {isEnglish  && <StyledMainHeaderImageHalf alt={"worksheet lab"} src={MainHeaderTitleEnImage}/>}
                {!isEnglish && <StyledMainHeaderImageHalf alt={"worksheet lab"} src={MainHeaderTitleJaImage}/>}
                <StyledSpacer/>
              </StyledContent>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <StyledContent>
                <StyledSpacer/>
                {isEnglish  && <StyledMainHeaderImageHalf alt={"worksheet lab"} src={MainHeaderCharImage}/>}
                {!isEnglish && <StyledMainHeaderImageHalf alt={"worksheet lab"} src={MainHeaderCharImage}/>}
              </StyledContent>
            </Grid>
          </Grid>
        </StyledContentLink>
      </StyledContentHeader>
      {children}
    </ThemeProvider>
  );
}

export default MainHeaderSmall;
