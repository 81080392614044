import * as React from "react";
import { useLocation } from "react-router-dom";
import { Grid, Button, Link } from '@mui/material';
import { ThemeProvider, styled } from "@mui/material/styles";

// local module
import { useAuth } from "../context/GlobalContext";
import { globalTheme } from "./GlobalStyle";

//-----------------------------------------------------------------------
// const
//
const globalHeaderFontSize = "x-small";
const globalHeaderPadding  = ".4em";

const globalHeaderHeight = "24px";
const globalFooterHeight = "24px";

const contentFooterHeight = "320px";

//-----------------------------------------------------------------------
// const message system
//
const labelGlobalHeaderMessageEn  = "WORKSHEET LABO | worksheet.nomurabbit.com";
const labelGlobalHeaderMessageJa  = "プリントらぼ | worksheet.nomurabbit.com";

const labelGlobalFooterMessageEn = "WORKSHEET LABO";
const labelGlobalFooterMessageJa = "プリントらぼ";

const labelLanguageSettingFlagEn = "Language";
const labelLanguageSettingFlagJa = "Language";

const labelLanguageSettingEn = "▶ JPN";
const labelLanguageSettingJa = "▶ ENG";

const labelAboutEn           = "▶ How to use this site";
const labelAboutJa           = "▶ このサイトについて";

const labelTermEn            = "▶ Term of Use";
const labelTermJa            = "▶ 利用規約";

const labelContactEn         = "▶ Contact us";
const labelContactJa         = "▶ お問い合わせ";

class globalMessageSystemClass {
  labelGlobalHeaderMessage: string;
  labelGlobalFooterMessage: string;
  labelLanguageSettingFlag: string;
  labelLanguageSetting:     string;
  labelAbout:               string;
  labelTerm:                string;
  labelContact:             string;
  constructor(
    labelGlobalHeaderMessage: string,
    labelGlobalFooterMessage: string,
    labelLanguageSettingFlag: string,
    labelLanguageSetting:     string,
    labelAbout:               string,
    labelTerm:                string,
    labelContact:             string
  ) {
    this.labelGlobalHeaderMessage = labelGlobalHeaderMessage;
    this.labelGlobalFooterMessage = labelGlobalFooterMessage;
    this.labelLanguageSettingFlag = labelLanguageSettingFlag;
    this.labelLanguageSetting     = labelLanguageSetting;
    this.labelAbout               = labelAbout;
    this.labelTerm                = labelTerm;
    this.labelContact             = labelContact;
  }
}

const globalMessageSystem = (language: boolean): globalMessageSystemClass => {
  if(language){
    return new globalMessageSystemClass(
      labelGlobalHeaderMessageEn,
      labelGlobalFooterMessageEn,
      labelLanguageSettingFlagEn,
      labelLanguageSettingEn,
      labelAboutEn,
      labelTermEn,
      labelContactEn
    );
  }else{
    return new globalMessageSystemClass(
      labelGlobalHeaderMessageJa,
      labelGlobalFooterMessageJa,
      labelLanguageSettingFlagJa,
      labelLanguageSettingJa,
      labelAboutJa,
      labelTermJa,
      labelContactJa
    );
  }
}

//-----------------------------------------------------------------------
// styled component (area)
//
const StyledGlobalHeader = styled("header")(({ theme }) => ({
  // background
  backgroundColor: theme.palette.success.light,
  // size
  height: globalHeaderHeight,
  // content
  display: "flex",
  alignItems: "center",
}));

const StyledGlobalFooter = styled("footer")(({ theme }) => ({
  // background
  backgroundColor: theme.palette.success.light,
  // size
  height: globalFooterHeight,
  // content
  display: "flex",
  alignItems: "center",
}));

const StyledGlobalFooterRoot = styled("div")(({ theme }) => ({
  // content
  flexGrow: 1,
  textAlign: "center",
}));

const StyledContentFooter = styled("footer")(({ theme }) => ({
  // background
  background: "linear-gradient(" + theme.palette.primary.light + "," + theme.palette.success.light + ")",
  // size
  height: contentFooterHeight,
  // content
  display: "flex",
  alignItems: "center",
}));

const StyledContentFooterRoot = styled("div")(({ theme }) => ({
  // size
  paddingLeft: 0,
  paddingRight: 0,
  paddingTop: "1.5em",
  paddingBottom: 0,
}));

const StyledHeaderSpacer = styled("div")(({ theme }) => ({
  // content
  display: "flex",
  flexGrow: 1,
}));

const StyledSpacer16 = styled("div")(({ theme }) => ({
  // content
  width: "16%",
}));

const StyledSpacer = styled("div")(({ theme }) => ({
  // content
  flexGrow: 1,
}));

//-----------------------------------------------------------------------
// styled component (content)
//
const StyledSpan = styled("span")(({ theme }) => ({
  // text
  color: theme.palette.primary.contrastText,
  fontSize: "small",
  padding: globalHeaderPadding,
}));

const StyledLanguageSpan = styled("span")(({ theme }) => ({
  // text
  color: theme.palette.primary.contrastText,
  fontSize: globalHeaderFontSize,
  padding: globalHeaderPadding,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  // text
  display: "flex",
  fontSize: globalHeaderFontSize,
  textTransform: 'none'
}));

const StyledLink = styled(Link)(({ theme }) => ({
}));

//-----------------------------------------------------------------------
// props
//
interface Props {
  children: React.ReactNode;
}

//-----------------------------------------------------------------------
// function component
//
const GlobalHeader: React.FC<Props> = ({children}) =>  {

  const location = useLocation();
  const {isEnglish, setLanguage, setTracking} = useAuth();

  React.useEffect(() => {
    setTracking(location.pathname + location.search);
  }, [location]);

  //---------------------------------------------------------------------
  // main
  //
  return (
    <ThemeProvider theme={globalTheme}>
      <StyledGlobalHeader>
        <StyledSpacer16/>
          <StyledSpan>
            {globalMessageSystem(isEnglish).labelGlobalHeaderMessage}
          </StyledSpan>
          <StyledHeaderSpacer/>
          <StyledLanguageSpan>
            {globalMessageSystem(isEnglish).labelLanguageSettingFlag}
          </StyledLanguageSpan>
          <StyledButton onClick={() => {setLanguage()}}>
            <StyledLanguageSpan>
              {globalMessageSystem(isEnglish).labelLanguageSetting}
            </StyledLanguageSpan>
          </StyledButton>
        <StyledSpacer16/>
      </StyledGlobalHeader>
      {children}
      <StyledContentFooter>
        <Grid container>
          <Grid item xs={2}><StyledSpacer/></Grid>
          <Grid item xs={8}>
            <Grid container>
              <Grid item xs={3}>
                <StyledContentFooterRoot>
                  <StyledLink href="/topics/about">
                    <StyledSpan>
                      {globalMessageSystem(isEnglish).labelAbout}
                    </StyledSpan>
                  </StyledLink>
                </StyledContentFooterRoot>
              </Grid>
              <Grid item xs={3}>
                <StyledContentFooterRoot>
                  <StyledLink href="/topics/terms">
                    <StyledSpan>
                      {globalMessageSystem(isEnglish).labelTerm}
                    </StyledSpan>
                  </StyledLink>
                </StyledContentFooterRoot>
              </Grid>
              <Grid item xs={3}>
                <StyledContentFooterRoot>
                  <StyledLink href="/topics/contact">
                    <StyledSpan>
                      {globalMessageSystem(isEnglish).labelContact}
                    </StyledSpan>
                  </StyledLink>
                </StyledContentFooterRoot>
              </Grid>
              <Grid item xs={3}>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2}><StyledSpacer/></Grid>
        </Grid>
      </StyledContentFooter>
      <StyledGlobalFooter>
        <StyledSpacer16/>
          <StyledGlobalFooterRoot>
            <StyledSpan>
              &copy; {globalMessageSystem(isEnglish).labelGlobalFooterMessage}
            </StyledSpan>
          </StyledGlobalFooterRoot>
        <StyledSpacer16/>
      </StyledGlobalFooter>
    </ThemeProvider>
  );
}

export default GlobalHeader;
